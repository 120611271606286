import { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

const DelayedTextField = (props) => {
    let { numbersOnly, value, delay, onChange } = props;
    // TODO - useRef may be better here if TextField can be made uncontrolled
    let [_value, set_Value] = useState(value ? value : '');
    let [timer, setTimer] = useState();

    useEffect(() => {
        set_Value(value)
    }, [ value, set_Value ])

    let handleChange = (_v) => {   
        if (numbersOnly) {            
            if (isNaN(_v)) {
                return;
            }
        }
        set_Value(_v);
        if (timer) {
            clearTimeout(timer);            
        } 
        let t = setTimeout(() => {
            onChange(_v);
            setTimer(null);
        }, delay);
        setTimer(t);
        return (() => {
            if (timer) {
                clearTimeout(timer);
            }
        })
    };
    let opacity = (timer && value) ? 0.5 : 0;
    return (
        <TextField
            InputProps={{
                endAdornment: 
                <InputAdornment position="start">
                    <EditIcon style={ { opacity }} color={'primary'} fontSize={'small'} />
                </InputAdornment>
            }}
            sx={props.sx}
            disabled={props.disabled}
            error={props.error}
            helperText={props.helperText}
            type={props.type}
            multiline={props.multiline}
            rows={props.rows}
            fullWidth={props.fullWidth}
            id={props.id}
            label={props.label}
            margin={props.margin}
            variant={props.variant}
            value={_value}
            color={props.color}
            size={props.size}
            onChange={(evt) => { handleChange(evt.target.value) }} />
    )
}

export default DelayedTextField