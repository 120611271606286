import { put } from './fetchUtil'

class JobTriggerApi {
    baseUrl: string;
    token: string | null;
    constructor() {
        this.baseUrl = ''
        this.token = null
    }

    setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    setToken(token: string | null) {
        this.token = token
    }

    async triggerScanSurveys() : Promise<void> {
        return put(this.baseUrl, `/jobs/scan/surveys`, null, () => this.token)
    }

    async triggerScanLayers() : Promise<void> {
        return put(this.baseUrl, `/jobs/scan/layers`, null, () => this.token)
    }

    async triggerRunJob(jobId : number) : Promise<void> {
        return put(this.baseUrl, `/jobs/run/${jobId}`, null, () => this.token)
    }
    
    async triggerRunAllJobs() : Promise<void> {
        return put(this.baseUrl, `/jobs/run`, null, () => this.token)
    }
    
}
export default new JobTriggerApi()