import { ImportJobDetail } from "../../model/ImportJob";
import { ReduxAction } from "../actionModel";

export const IMPORTTASK_LIST_INIT = "[importtasklist] init"
export const IMPORTTASK_SET_JOB_DETAIL = "[importtasklist] set job detail"

export const IMPORTTASK_LIST_STARTWATCH = "[importtasklist] start watch"
export const IMPORTTASK_LIST_ENDWATCH = "[importtasklist] end watch"


export interface ImportTaskListInitAction {
    type: string;
    payload: { id: string; }
}

export const importTaskListInit = (id: string) : ImportTaskListInitAction => ({
    type: IMPORTTASK_LIST_INIT,
    payload: { id }
})

export interface ImportTaskListSetJobDetailAction {
    type: string;
    payload: ImportJobDetail
}

export const setImportJobDetail = (job: ImportJobDetail) : ImportTaskListSetJobDetailAction => ({
    type: IMPORTTASK_SET_JOB_DETAIL,
    payload: job
})

export const importTaskListStartWatch = (id: string) : ImportTaskListInitAction => ({
    type: IMPORTTASK_LIST_STARTWATCH,
    payload: { id }
})

export const importTaskListEndWatch = () : ReduxAction => ({
    type: IMPORTTASK_LIST_ENDWATCH 
})