import {
  put,
  select,
  takeLeading,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects";
import produce from "immer";
import { ListResponse, SingleResponse } from "../../../model/ListResponse";
import { Observation, ObservationFilter } from "../../../model/Observation";
import { filterObservations } from "../../../service/observationFilter";
import surveyApi from "../../../service/surveyApi";
import { ReduxAction } from "../../actionModel";
import { apiRequest } from "../../api/apiRequest";
import { State } from "../../reducers";
import { showMessage } from "../../ui/uiActions";
import {
  appendObservations,
  observationFilterChanged,
  ObservationRequestAction,
  ObservationRequestNextAction,
  observationsLoaded,
  OBSERVATIONS_NEXT_PAGE,
  OBSERVATIONS_PREV_PAGE,
  OBSERVATION_REQUEST,
  OBSERVATION_REQUEST_NEXT,
  OBSERVATION_RESET,
  OBSERVATION_SELECT_OBSERVATIONS,
  OBSERVATION_SET_FILTERED_OBSERVATIONS,
  OBSERVATION_SET_OBSERVATIONS,
  OBSERVATION_SET_OBSERVATIONS_FILTER,
  OBSERVATION_SET_OBSERVATIONS_PAGE,
  OBSERVATION_UPDATE,
  requestObservationsNext,
  SelectObservationsAction,
  setObservations,
  SetObservationsAction,
  SetObservationsFilterAction,
} from "./observationActions";

function* processRequestObservation(action: ObservationRequestAction) {
  try {
    const { surveyId } = action.payload;
    yield put({ type: OBSERVATION_RESET, payload: surveyId });
    yield put(requestObservationsNext(surveyId, 1));
  } catch (error) {
    console.log(error);
  }
}

function* processRequestObservationsNext(action: ObservationRequestNextAction) {
  try {
    const { surveyId, page } = action.payload;
    const _surveyId: number | null = yield select(
      (state: State) => state.observations.surveyId
    );
    if (surveyId !== _surveyId) {
      return;
    }
    const data: ListResponse<Observation> = yield apiRequest(
      surveyApi,
      surveyApi.loadObservationsPage,
      [surveyId, page, 1000],
      {
        error: (error: any) =>
          showMessage({ title: "Fejl", text: error.toString() }),
      }
    );
    yield put(appendObservations(data.items));
    if (data.pages > page) {
      yield put(requestObservationsNext(surveyId, page + 1));
    } else {
      const { filter } = yield select((state: State) => state.observations);
      yield put(observationFilterChanged(filter));
      yield put(observationsLoaded());
    }
  } catch (error) {
    console.log(error);
  }
}

function* processObservationsPrevPage(action: ReduxAction) {
  try {
    let currPage: number = yield select(
      (state: State) => state.observations.currentPage
    );
    if (currPage === 1) {
      return;
    }
    yield put({
      type: OBSERVATION_SET_OBSERVATIONS_PAGE,
      payload: currPage - 1,
    });
  } catch (error) {
    console.log(error);
  }
}

function* processObservationsNextPage(action: ReduxAction) {
  try {
    let currPage: number = yield select(
      (state: State) => state.observations.currentPage
    );
    let pageSize: number = yield select(
      (state: State) => state.observations.pageSize
    );
    let filteredObservations: Observation[] | null = yield select(
      (state: State) => state.observations.filteredObservations
    );
    if (filteredObservations === null || filteredObservations.length === 0) {
      return;
    }
    let maxPage = 1 + ~~((filteredObservations.length - 1) / pageSize);
    console.log({ maxPage });
    if (currPage + 1 > maxPage) {
      return;
    }
    yield put({
      type: OBSERVATION_SET_OBSERVATIONS_PAGE,
      payload: currPage + 1,
    });
  } catch (error) {
    console.log(error);
  }
}

function* processSetObservationsFilter(action: SetObservationsFilterAction) {
  try {
    const observations: Observation[] | null = yield select(
      (state: State) => state.observations.observations
    );
    if (observations === null || observations.length === 0) {
      return;
    }
    yield put({
      type: OBSERVATION_SET_OBSERVATIONS_PAGE,
      payload: 1,
    });
    yield put({
      type: OBSERVATION_SET_FILTERED_OBSERVATIONS,
      payload: filterObservations(observations, action.payload),
    });
  } catch (error) {
    console.log(error);
  }
}

function* processSetObservations(action: SetObservationsAction) {
  try {
    const observationFilter: ObservationFilter = yield select(
      (state: State) => state.observations.filter
    );
    const observations = action.payload;
    if (observations === null || observations.length === 0) {
      return;
    }
    // TODO - add stayOnPage option to payload
    yield put({
      type: OBSERVATION_SET_OBSERVATIONS_PAGE,
      payload: 1,
    });
    yield put({
      type: OBSERVATION_SET_FILTERED_OBSERVATIONS,
      payload: filterObservations(observations, observationFilter),
    });
  } catch (error) {
    console.log(error);
  }
}

function* processUpdateObservation(action: any) {
  try {
    const observations: Observation[] | null = yield select(
      (state: State) => state.observations.observations
    );
    const patch = produce(action.payload.patch, (draft: any) => {
      draft.surveyId = undefined;
      draft.id = undefined;
    });
    yield apiRequest(
      surveyApi,
      surveyApi.updateObservation,
      [action.payload.surveyId, action.payload.observationId, patch],
      {
        "200": (data: SingleResponse<Observation>) => {
          const obs = observations
            ? observations.map((o) => {
                if (o.id === action.payload.observationId) {
                  return { ...data.item, isSelected: 1 };
                } else {
                  return produce(o, (draft) => draft);
                }
              })
            : [];
          return setObservations(obs);
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
}

function* processSelectObservations(action: SelectObservationsAction) {
  try {
    yield console.log("todo");
  } catch (error) {}
}

export default function* watcher() {
  yield takeLeading(OBSERVATION_REQUEST, processRequestObservation);
  yield takeEvery(OBSERVATION_REQUEST_NEXT, processRequestObservationsNext);
  yield takeLeading(OBSERVATIONS_PREV_PAGE, processObservationsPrevPage);
  yield takeLeading(OBSERVATIONS_NEXT_PAGE, processObservationsNextPage);
  yield takeLatest(
    OBSERVATION_SET_OBSERVATIONS_FILTER,
    processSetObservationsFilter
  );
  yield takeEvery(OBSERVATION_UPDATE, processUpdateObservation);
  yield takeEvery(OBSERVATION_SET_OBSERVATIONS, processSetObservations);
  yield takeEvery(OBSERVATION_SELECT_OBSERVATIONS, processSelectObservations);
}
