import produce from "immer";
import { ImportJobDetail } from "../../model/ImportJob";
import { ImportTaskListSetJobDetailAction, IMPORTTASK_SET_JOB_DETAIL } from "./importTaskListActions";

export interface ImportTaskListStore {
    job: ImportJobDetail | null
}

const initialState: ImportTaskListStore = {
    job: null
};

const importTaskList = (state = initialState, action: any) => {
  switch (action.type) {
    case IMPORTTASK_SET_JOB_DETAIL:
        const setJobDetailAction : ImportTaskListSetJobDetailAction = action
        return produce(state, (draft: ImportTaskListStore) => { 
                draft.job = setJobDetailAction.payload; 
                return draft 
            })
    default:
      return state;
  }
};

export default importTaskList;
