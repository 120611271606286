import { memo, useState, useEffect } from "react";

import {
  Button,
  LinearProgress,
  Grid,
  Typography,
  Modal,
  Paper,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import PendingOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import {
  UploadFile,
  UploadProgress,
  UploadStatus,
} from "../../redux/upload/uploadReducer";
import UploadButton from "./UploadButton";
import StaticDataList, {
  ConditionalCell,
  ConditionEquals,
  FileSizeCell,
  TextFieldCell,
} from "../dataList/StaticDataList";
import surveyApi from "../../service/surveyApi";

const readFile = (file: File): Promise<ArrayBuffer | string | null> => {
  const promise: Promise<ArrayBuffer | string | null> = new Promise(
    (resolve, reject) => {
      const fr = new FileReader();
      fr.onload = function (e) {
        // binary data
        if (!e || !e.target) {
          return;
        }
        resolve(e.target.result);
      };
      fr.onerror = function (e) {
        reject(e);
      };
      fr.readAsArrayBuffer(file);
    }
  );
  return promise;
};

export interface UploadComponentProps {
  disabled?: boolean;
  season: number | null;
  dataLocation: string;
}

const UploadComponent = ({
  disabled,
  season,
  dataLocation,
}: UploadComponentProps) => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<UploadProgress | null>(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (files.length === 0) {
        setProgress(null)
        return
    }
    setProgress({
        complete: files.filter((f) => f.status === "ok").length,
        total: files.length,
      });
  }, [ files, setProgress ])

  if (!season) {
    return <Button disabled>Upload</Button>;
  }
  const _season = season;

  console.log({ files, progress, uploading })

  const updateFileStatus = (files: UploadFile[], filename: string, status: UploadStatus) : UploadFile[] => {
    const _files = files.map((f) => {
      if (f.name !== filename) {
        return f;
      }
      return { ...f, status };
    });
    setFiles(_files);   
    return _files
  };

  const handleChange = async (
    season: number,
    surveyName: string,
    filelist: FileList
  ): Promise<void> => {
    if (!filelist) {
      console.log("filelist null");
      return;
    }
    let _files: File[] = [];
    for (let i = 0; i < filelist.length; i++) {
      const file: File | null = filelist && filelist[i];
      if (file === null) {
        continue;
      }
      _files.push(file);
    }
    if (_files.length === 0) {
      console.log("no files selected");
      return;
    }
    setUploading(true);
    console.log({_files })
    let uploadFiles = _files.map(
        (f: File): UploadFile => ({
          name: f.name,
          status: "pending",
          size: f.size,
        })
      )
    setFiles(uploadFiles)
    for (let i = 0; i < _files.length; i++) {
      const file: File = _files[i];
      console.log({ file: file.name });
      const data = await readFile(file);
      if (!data) {
        console.log({ file: file.name, data });
        uploadFiles = updateFileStatus(uploadFiles, file.name, "failed");
        continue;
      }
      uploadFiles = updateFileStatus(uploadFiles, file.name, "in_progress");      
      try {
        const urlResponse = await surveyApi.getUploadUrl(
            season,
            surveyName,
            file.name
          );  await fetch(urlResponse.url, {
          method: "PUT",
          body: data,
        });
        uploadFiles = updateFileStatus(uploadFiles, file.name, "ok");
      } catch {
        uploadFiles = updateFileStatus(uploadFiles, file.name, "failed");
      }
    }
    setUploading(false);
  };

  return (
    <>
      <Modal
        open={progress !== null}
        sx={{ margin: "auto", marginTop: 8, padding: 2, maxWidth: 800 }}
        onClose={() => setProgress(null)}
      >
        <Paper sx={{ padding: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Uploading</Typography>
            </Grid>
            <Grid item xs={12}>
              <StaticDataList items={files}>
                <TextFieldCell title="Name" source="name" />
                <FileSizeCell title="Size" source="size" />
                <ConditionalCell minWidth={120} title="Status" source="status">
                  <ConditionEquals case="ok">
                    <DoneIcon color="success" fontSize="small" />
                  </ConditionEquals>
                  <ConditionEquals case="pending">
                    <PendingOutlinedIcon color="inherit" fontSize="small" />
                  </ConditionEquals>
                  <ConditionEquals case="in_progress">
                    <CircularProgress variant="indeterminate" size="1.25rem" />
                  </ConditionEquals>
                  <ConditionEquals case="failed">
                    <span>Fejl</span>
                  </ConditionEquals>
                </ConditionalCell>
              </StaticDataList>
            </Grid>
            <Grid item xs={12}>
              {progress && progress.total > 0 ? (
                <LinearProgress
                  sx={{ height: 8 }}
                  variant="determinate"
                  value={(progress.complete * 100) / progress.total}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => setProgress(null)}
                disabled={!progress || progress.complete < progress.total}
                fullWidth
                variant="contained"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <UploadButton
        label="Upload"
        disabled={uploading || disabled}
        variant="contained"
        onChange={(evt: any, filelist: FileList | null) => {
          console.log("Upload", { filelist, _season, dataLocation });
          if (!filelist) {
            setFiles([]);
          } else {
            console.log('calling handle change')
            handleChange(_season, dataLocation, filelist);
          }
        }}
        color="inherit"
      />
    </>
  );
};

function areEqual(prevProps : UploadComponentProps, nextProps: UploadComponentProps) {
    return prevProps.season === nextProps.season && prevProps.dataLocation === nextProps.dataLocation
  }

export default memo(UploadComponent, areEqual)
