import { SetSurveysAction, SURVEYS_SET_FILTER, SURVEYS_SET_SURVEYS } from "./surveyListActions"
import { SurveyFilter } from "../../model/SurveyFilter"
import { Survey } from "../../model/Survey";


export interface SurveyListStore {
  filter: SurveyFilter;
  surveys: Array<Survey>;
}

const initialState : SurveyListStore = {
    filter: { season: '', fetchall: false },
    surveys: []
}



const surveyListStore = (state : SurveyListStore = initialState, action: any) : SurveyListStore => {
    switch (action.type) {
      case SURVEYS_SET_SURVEYS:
        const setSurveysAction : SetSurveysAction = action
        return { ...state, surveys: setSurveysAction.payload }
      case SURVEYS_SET_FILTER:          
        return { ...state, filter: action.payload }
      default:
        return state
    }
  }
  
  export default surveyListStore;