import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchImportJobs, initImportJobPage, setAutoRefresh, setImportJobFilter } from "../redux/importjob/importJobActions";
import { State } from '../redux/reducers'
import { 
  Button, 
  FormControlLabel,
  Checkbox,
  Grid } from "@mui/material";
import { triggerRunAllJobs, triggerScanSurveys } from "../redux/importjob/jobTriggerActions";
import { useNavigate } from "react-router-dom";
import { ImportJobSimple, ImportStatusType } from "../model/ImportJob";
import Select from "react-select";
import produce from "immer";
import { ImportJobFilter } from "../model/ImportJobFilter";
import DelayedTextField from "../components/DelayedTextField";
import StaticDataList, { ConditionEquals, ConditionalCell, TextFieldCell } from "../components/dataList/StaticDataList";

const valueToOption = (value : ImportStatusType) : { value: string, label: string } => ({
  value, label: value.toLocaleLowerCase()
})

const optionToValue = (option : { value: string, label: string }) : ImportStatusType => option.value as ImportStatusType

const statuses : ImportStatusType[] = [
  'pending', 'in_progress', 'finished',  'aborted', 'failed'
]

const ImportListPage = (props: any) => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { importJobs, filter, autoRefresh } = useSelector((state: State) => state.importjob)
  const { loading } = useSelector((state: State) => state.ui)
  useEffect(() => {
    dispatch(initImportJobPage())
  }, [ dispatch ])
  
  console.log({ filter, importJobs})

  const jobs = importJobs

  const updateFilterStatus = (status : ImportStatusType[]) => {
    const nf : ImportJobFilter = produce((draft : ImportJobFilter) : ImportJobFilter => {
      draft.statuses = status
      return draft
    }, filter)()
    console.log(nf)
    dispatch(setImportJobFilter(nf))
  }

  const updateFilterLocation = (value : string) => {
    const nf : ImportJobFilter = produce((draft : ImportJobFilter) : ImportJobFilter => {
      draft.dataLocationSearch = value
      return draft
    }, filter)()
    console.log(nf)
    dispatch(setImportJobFilter(nf))
  }

  return (<div>
    <Grid container>
      <Grid item xs={12}>
        <Button onClick={() => { dispatch(triggerScanSurveys())}}>Scan</Button>
        <Button onClick={() => { dispatch(triggerRunAllJobs())}}>Run all</Button>
        <Button disabled={autoRefresh} onClick={() => { dispatch(fetchImportJobs(filter))}}>Refresh</Button>
        <FormControlLabel label="Auto Refresh" sx={{ marginLeft: 100, marginRight: 0 }} 
          control={
            <Checkbox disabled={autoRefresh && loading} checked={autoRefresh} onChange={(evt) => dispatch(setAutoRefresh(evt.target.checked))} />
          } />                      
      </Grid>
      <Grid item xs={12}>
        <Select      
          placeholder='Select status to filter by...'
          isClearable
          isMulti
          value={filter.statuses.map(valueToOption)}
          options={statuses.map(valueToOption)}
          onChange={(v) => updateFilterStatus(v.map(optionToValue))} />
      </Grid>
      <Grid item xs={12}>
        <DelayedTextField delay={200} sx={{ margin: 1, marginBottom: 2 }} variant="standard" label='Filter by location..' value={filter.dataLocationSearch} onChange={(value : string) => updateFilterLocation(value)} />
      </Grid>
    </Grid>
    <StaticDataList loading={loading && !autoRefresh} items={jobs} onClick={(job: ImportJobSimple) => {
        navigate('/import/' + job.id)
      }}>
        <TextFieldCell source='season' title='Sæson' />
        <TextFieldCell source='dataLocation' title='Lokation' />
        <TextFieldCell source='created' title='Oprettet' />
        <TextFieldCell source='finished' title='Afsluttet' />
        <ConditionalCell source='status' title='Status'>
          <ConditionEquals case='FINISHED'><span>Finished</span></ConditionEquals>
          <ConditionEquals case='IN_PROGRESS'><span>In progress</span></ConditionEquals>
          <ConditionEquals case='FAILED'><span>Failed</span></ConditionEquals>
          <ConditionEquals case='ABORTED'><span>Aborted</span></ConditionEquals>
          <ConditionEquals case='PENDING'><span>Pending</span></ConditionEquals>
        </ConditionalCell>
      </StaticDataList>
  </div>);
};

export default ImportListPage