import React, { ReactElement, useEffect, useState } from "react";
import TileLayer from "ol/layer/Tile";
import XYZSource from "ol/source/XYZ";
import "ol/ol.css";
import { Map } from "ol";

export interface OlTileLayerProps {
  url: string;
  map?: Map;
}

export const OlTileLayer = (props: OlTileLayerProps) => {
  const { map, url } = props;
  // eslint-disable-next-line
  const [tileLayer, setTileLayer] = useState<TileLayer<XYZSource>>(
    new TileLayer({
      source: new XYZSource({
        url: url,
      }),
    })
  );
  useEffect(() => {
    map?.addLayer(tileLayer);
  }, [map, tileLayer]);
  return <div></div>;
};

const OlLayers = (props: {
  children: ReactElement | ReactElement[];
  map?: Map;
}) => {
  const { children, map } = props;
  const _children: ReactElement[] = Array.isArray(children)
    ? children
    : [children];
  if (!map) {
    return null;
  }
  return (
    <React.Fragment>
      {_children.map((c: ReactElement, idx: number) => {
        return React.createElement(c.type, { ...c.props, map: map, key: idx });
      })}
    </React.Fragment>
  );
};

export default OlLayers;
