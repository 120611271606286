import { Observation, ObservationFilter } from '../../../model/Observation'
import { ReduxAction } from '../../actionModel'

export const OBSERVATION_REQUEST = '[survey][observation] request'
export const OBSERVATION_REQUEST_NEXT = "[survey][observation] request next observation"
export const OBSERVATION_RESET = "[survey][observation] reset observation"
export const OBSERVATION_APPEND = "[survey][observation] append observation"
export const OBSERVATION_LOADED = "[survey][observation] observation loaded"
export const OBSERVATION_SET_PAGES = "[survey][observation] set pages"
export const OBSERVATION_SET_PAGE = "[survey][observation] set page"

export const OBSERVATION_SET_OBSERVATIONS = "[survey][observation] set observations"
export const OBSERVATION_SET_FILTERED_OBSERVATIONS = "[survey] set filtered observations"
export const OBSERVATION_SELECT_OBSERVATIONS = "[survey] select observation"
export const OBSERVATION_SET_OBSERVATIONS_FILTER = "[survey] set observation filter"
export const OBSERVATION_SET_OBSERVATIONS_PAGE = "[survey] set observations page"
export const OBSERVATIONS_PREV_PAGE = "[survey] observations prev page"
export const OBSERVATIONS_NEXT_PAGE = "[survey] observations next page"
export const OBSERVATION_UPDATE = "[survey] observation request update"
export const OBSERVATION_SET_OBSERVATIONS_PAGESIZE = "[survey] set observations pagesize"


export interface ObservationRequestAction extends ReduxAction {
    payload: { surveyId: number; }
}

export interface ObservationRequestNextAction extends ReduxAction {    
    payload: { surveyId: number; page: number; }
}

export interface ObservationAppendAction extends ReduxAction {
    payload: { observations: Observation[] }
}

export interface SetObservationsAction {
    type: string;
    payload: Observation[];
}

export interface SetObservationsFilterAction {
    type: string;
    payload: ObservationFilter;
}

export interface SelectObservationsAction {
    type: string;
    payload: { id: number };
}

export const requestObservations = (surveyId: number) : ObservationRequestAction => ({
    type: OBSERVATION_REQUEST,
    payload: { surveyId }
})

export const observationsLoaded = () : ReduxAction => ({
    type: OBSERVATION_LOADED
})

export const appendObservations = (observations: Observation[]) => ({
    type: OBSERVATION_APPEND,
    payload: { observations }
})

export const requestObservationsNext = (surveyId: number, page: number) : ObservationRequestNextAction => ({
    type: OBSERVATION_REQUEST_NEXT,
    payload: { surveyId, page }
})

export const selectObservations = (id: number) : SelectObservationsAction => ({
    type: OBSERVATION_SELECT_OBSERVATIONS,
    payload: { id }
})

export const observationsPrevPage = () => ({
    type: OBSERVATIONS_PREV_PAGE
})

export const observationsNextPage = () => ({
    type: OBSERVATIONS_NEXT_PAGE
})

export const observationFilterChanged = (filter: ObservationFilter) : SetObservationsFilterAction => ({
    type: OBSERVATION_SET_OBSERVATIONS_FILTER,
    payload: filter
})

export const requestUpdateObservation = (surveyId: number, observationId: number, patch: any) => ({
    type: OBSERVATION_UPDATE,
    payload: { surveyId, observationId, patch }
})

export const setObservations = (observations: Observation[]) : SetObservationsAction => ({
    type: OBSERVATION_SET_OBSERVATIONS,
    payload: observations
})