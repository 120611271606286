import { 
    put,
    select,
    takeLeading, 
    takeEvery
} from "@redux-saga/core/effects";
import { ListResponse } from "../../../model/ListResponse";
import { Tracklog } from "../../../model/Tracklog";
import surveyApi from "../../../service/surveyApi";
import { apiRequest } from "../../api/apiRequest";
import { State } from "../../reducers";
import { showMessage } from "../../ui/uiActions";
import { appendTracklog, requestTracklogNext, tracklogLoaded, TracklogRequestAction, TracklogRequestNextAction, TRACKLOG_REQUEST, TRACKLOG_REQUEST_NEXT, TRACKLOG_RESET } from "./tracklogActions";

function* processRequestTracklog(action : TracklogRequestAction) {
    try {
        const { surveyId } = action.payload
        yield put({ type: TRACKLOG_RESET, payload: surveyId})
        yield put(requestTracklogNext(surveyId, 1))
    } catch (error) {
        console.log(error)
    }
}

function* processRequestTracklogNext(action : TracklogRequestNextAction) {
    try {
        const { surveyId, page } = action.payload
        const _surveyId : number | null = yield select((state: State) => state.tracklog.surveyId)
        if (surveyId !== _surveyId) {
            return
        }
        const data : ListResponse<Tracklog> = yield apiRequest(surveyApi, surveyApi.loadTracklogPage, [ surveyId, page, 1000 ], {
            "error": (error: any) => showMessage({ 'title': 'Fejl', 'text': error.toString()})
        })
        yield put(appendTracklog(data.items))
        if (data.pages > page) {                  
            yield put(requestTracklogNext(surveyId, page + 1))
        } else {
            yield put(tracklogLoaded())
        }
    } catch (error) {
        console.log(error)
    }
}

export default function* watcher() {
    yield takeLeading(TRACKLOG_REQUEST, processRequestTracklog)
    yield takeEvery(TRACKLOG_REQUEST_NEXT, processRequestTracklogNext)
}
