import {
  SetFilesAction,
  SetUploadProgressAction,
  UPLOAD_COMPLETE,
  UPLOAD_RESET_PROGRESS,
  UPLOAD_SET_FILES,
  UPLOAD_SET_PROGRESS,
  UPLOAD_STARTED,
} from "./uploadActions";

export type UploadStatus = "pending" | "ok" | "failed" | "in_progress";

export interface UploadFile {
  name: string;
  size?: number;
  status: UploadStatus;
}

export interface UploadProgress {
  complete: number;
  total: number;
}

export interface UploadStore {
  files: UploadFile[];
  uploading: boolean;
  progress: null | UploadProgress;
}

const initialState: UploadStore = {
  files: [],
  uploading: false,
  progress: null,
};

const uploadStore = (
  state: UploadStore = initialState,
  action: any
): UploadStore => {
  switch (action.type) {
    case UPLOAD_STARTED:
      return { ...state, uploading: true };
    case UPLOAD_COMPLETE:
      return { ...state, uploading: false };
    case UPLOAD_SET_PROGRESS:
      const setProgressAction = action as SetUploadProgressAction;
      return {
        ...state,
        progress: {
          complete: setProgressAction.payload.complete,
          total: setProgressAction.payload.total,
        },
      };
    case UPLOAD_RESET_PROGRESS:
      return { ...state, progress: null };
    case UPLOAD_SET_FILES:
      const setFilesAction: SetFilesAction = action as SetFilesAction;
      return { ...state, files: setFilesAction.payload.files };
    default:
      return state;
  }
};

export default uploadStore;
