import { call, put, takeLeading } from "@redux-saga/core/effects";

import { ConfirmActionAction, GotoAction, UI_CONFIRM_ACTION, UI_GOTO, showMessage } from '../ui/uiActions'

function* processConfirmAction(action: ConfirmActionAction) {
    try {
        const confirmedAction = action.payload.action
        yield put(confirmedAction)
    } catch (error) {
        yield put(showMessage({ title: 'Fejl', text: 'Fejl i confirm action' }))
    }
}

function* processGoto(action: GotoAction) {
    try {
        yield call(() => window.location.href = `/#/${action.payload.page}`)
    } catch (error) {
        console.error(error)
    }
}

export default function* watcher() {
    yield takeLeading(UI_CONFIRM_ACTION, processConfirmAction)
    yield takeLeading(UI_GOTO, processGoto)
}
