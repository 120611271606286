import { Environment } from "../../../model/Environment"

export const ENVIRONMENT_INIT = "[environment] init"
export const ENVIRONMENT_SET = "[environment] set"
export const ENVIRONMENT_SET_PAGE = "[environment] set page"
export const ENVIRONMENT_PREV_PAGE = "[environment] prev page"
export const ENVIRONMENT_NEXT_PAGE = "[environment] next page"

export interface EnvironmentInitAction {
    type: string,
    payload: { surveyId: number }
}

export interface EnvironmentSetAction {
    type: string,
    payload: Environment[]
}

export const initEnvironment = (surveyId: number) : EnvironmentInitAction => ({
    type: ENVIRONMENT_INIT,
    payload: { surveyId }
})

export const setEnvironment = (data: Environment[]) : EnvironmentSetAction => ({
    type: ENVIRONMENT_SET,
    payload: data
})

export const environmentPrevPage = () => ({
    type: ENVIRONMENT_PREV_PAGE
})

export const environmentNextPage = () => ({
    type: ENVIRONMENT_NEXT_PAGE
})

export const environmentSetPage = (page: number) => ({
    type: ENVIRONMENT_SET_PAGE,
    payload: page
})