//import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HashRouter as Router, Routes, Route } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from "@mui/material";

import Dashboard from "./layout/Dashboard";
import SurveyEditPage from "./pages/SurveyEditPage";
import SurveyListPage from "./pages/SurveyListPage";
import ImportListPage from "./pages/ImportListPage";

import { State } from "./redux/reducers";
import { cancelAction, confirmAction, hideMessage } from "./redux/ui/uiActions";

import { Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import surveyApi from "./service/surveyApi";
import { init, requestLogout } from "./redux/app/appActions";
import ImportTaskListPage from "./pages/ImportTaskListPage";
import { useEffect } from "react";
import UploadPage from "./pages/UploadPage";

export const LoginAmplify = function () {
  return (
    <Authenticator>
      {({ signOut, user } : { signOut: any, user: any}) => {
        console.log(Auth.currentAuthenticatedUser().then(user => console.log(user)))
        console.log(user?.getSignInUserSession()?.getIdToken().getJwtToken())
        if (user) {
          surveyApi.setToken(user.getSignInUserSession()?.getIdToken().getJwtToken() || null)
        }
        return (
          <App signOut={signOut} />
        );
      }}
    </Authenticator>
  );
};

function App(props : any) {
  const dispatch = useDispatch();
  const { version } = useSelector((state: State) => state.app);
  const { message, confirm} = useSelector((state: State) => state.ui);

  useEffect(() => {
    dispatch(init())
  }, [ dispatch ])

  return (
    <Router>
      <Dialog
        open={Boolean(message)}
        onClose={() => dispatch(hideMessage())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message?.title}</DialogTitle>
        <DialogContent>
          <Typography>{message?.text}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(hideMessage())}
            variant="outlined"
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(confirm)}
        onClose={() => dispatch(cancelAction())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirm?.message?.title}</DialogTitle>
        <DialogContent>
          <Typography>{confirm?.message?.text}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => confirm?.action && dispatch(confirmAction(confirm?.action))}
            variant="outlined"
            color="primary"
          >
            Ok
          </Button>
          <Button
            onClick={() => dispatch(cancelAction())}
            variant="outlined"
            color="primary"
          >
            Fortryd
          </Button>
        </DialogActions>
      </Dialog>
      <Dashboard version={version} title="Flydata" onSignOut={() => dispatch(requestLogout())} >
        <Routes>
          <Route path="/" element={<SurveyListPage />} />
          <Route path="/survey" element={<SurveyListPage />} />
          <Route path="/survey/:id" element={<SurveyEditPage />} />
          <Route path="/import" element={<ImportListPage />} />
          <Route path="/import/:id" element={<ImportTaskListPage />} />
          <Route path="/upload" element={<UploadPage />} />
        </Routes>
      </Dashboard>
    </Router>
  );
}

export default App;
