import { Tracklog } from '../../../model/Tracklog'
import { ReduxAction } from '../../actionModel'
export const TRACKLOG_REQUEST = "[survey][tracklog] request tracklog"
export const TRACKLOG_REQUEST_NEXT = "[survey][tracklog] request next tracklog"
export const TRACKLOG_RESET = "[survey][tracklog] reset tracklog"
export const TRACKLOG_APPEND = "[survey][tracklog] append tracklog"
export const TRACKLOG_LOADED = "[survey][tracklog] tracklog loaded"

export interface TracklogRequestAction extends ReduxAction {
    payload: { surveyId: number; }
}

export interface TracklogRequestNextAction extends ReduxAction {    
    payload: { surveyId: number; page: number; }
}

export interface TracklogAppendAction extends ReduxAction {
    payload: { tracklog: Tracklog[] }
}

export const requestTracklog = (surveyId: number) : TracklogRequestAction => ({
    type: TRACKLOG_REQUEST,
    payload: { surveyId }
})

export const tracklogLoaded = () : ReduxAction => ({
    type: TRACKLOG_LOADED
})

export const appendTracklog = (tracklog: Tracklog[]) => ({
    type: TRACKLOG_APPEND,
    payload: { tracklog }
})

export const requestTracklogNext = (surveyId: number, page: number) : TracklogRequestNextAction => ({
    type: TRACKLOG_REQUEST_NEXT,
    payload: { surveyId, page }
})