import { Observation, ObservationFilter } from '../model/Observation'

export const filterObservations = (observations: Observation[] | null, filter: ObservationFilter) : Observation[] => {
  if (!observations) {
    return []
  }
  return observations.filter((o : Observation) => {
      let accept = true
      if (accept && filter.minCount) {
        accept = o.count >= filter.minCount
      }
      if (accept && filter.speciesId !== null) {
        accept = o.speciesId === filter.speciesId
      }
      return accept
  })
}