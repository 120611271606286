import { Observer } from "../../model/Observer";
import { Survey } from "../../model/Survey";
import {
  SetSurveyAction,
  SetShadowSurveyAction,
  SURVEY_SET_SURVEY,
  SURVEY_SET_SHADOW_SURVEY,
  SURVEY_PAGE_SET_OBSERVERS,
  SetObserversAction,
  SetTransectLinesAction,
  SURVEY_SET_TRANSECTLINES,
  SURVEY_MAP_ZOOM_TO,
  SURVEY_CLEAR_SURVEY,
} from "./surveyActions";

export interface MapView {
  lat: number;
  lon: number;
  zoom: number;
}

export interface SurveyStore {
  survey: Survey | null;
  shadowSurvey: Survey | null;
  observers: Observer[];
  transectLines: any[] | null;
  mapView: MapView;
}

const initialState: SurveyStore = {
  survey: null,
  shadowSurvey: null,
  transectLines: null,
  observers: [],
  mapView: { lat: 56, lon: 10.5, zoom: 7 }
};

const surveyStore = (
  state: SurveyStore = initialState,
  action: any
): SurveyStore => {
  switch (action.type) {   
    
    case SURVEY_SET_TRANSECTLINES:
      const setTransectLinesAction: SetTransectLinesAction = action;
      return { ...state, transectLines: setTransectLinesAction.payload };    
    case SURVEY_SET_SHADOW_SURVEY: 
      const setShadowSurveyAction: SetShadowSurveyAction = action;
      return { ...state, shadowSurvey: setShadowSurveyAction.payload };
    case SURVEY_CLEAR_SURVEY:
      return { ...state, survey: null, shadowSurvey: null };
    case SURVEY_SET_SURVEY:
      const setSurveyAction: SetSurveyAction = action;
      return { ...state, survey: setSurveyAction.payload, shadowSurvey: null };
    case SURVEY_PAGE_SET_OBSERVERS:
      const setObserversAction : SetObserversAction = action;
      return { ...state, observers: setObserversAction.payload }
    case SURVEY_MAP_ZOOM_TO:
      const zoomToAction : any = action
      const { coordinates } = zoomToAction.payload
      const newState = { ...state, mapView: { lon: coordinates[0], lat: coordinates[1], zoom: 9.5 }}
      console.log({newState})
      return newState
    default:
      return state;
  }
};

export default surveyStore;
