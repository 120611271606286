import React, { useEffect, useState } from "react";
import { Theme, useTheme } from '@mui/material/styles';

import {
  Box,
  Link,
  Paper,
  TextField,
  Button,
  Grid,
  CssBaseline,
  Avatar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  requestLogin,
  initAuth,
  forgotPassword,
} from "../../redux/app/appActions";
import { State } from "../../redux/reducers";
import { initAmplify } from "../../redux/amplify/amplifyActions";
import { makeStyles } from "@mui/styles";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import { AuthChallenge } from "../../redux/app/appReducer";
import { hideMessage } from "../../redux/ui/uiActions";
import NewPasswordRequiredComponent from "./NewPasswordRequiredComponent";

const useStyles = (theme: Theme) => makeStyles(() => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://source.unsplash.com/1600x900/?nature,water)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[50],        
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))()

const Copyright = ({ version }: { version: string }) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Flydata"}
      <Link color="inherit" href="https://material-ui.com/"></Link>{" "}
      {`v${version}`}
    </Typography>
  );
};

const SignInComponent = function () {
  const dispatch = useDispatch();
  const theme = useTheme()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  
  const classes = useStyles(theme);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <div className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(evt) => setUsername(evt.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
          autoComplete="current-password"
        />
        <Button
          onClick={() => {
            dispatch(requestLogin(username, password));
          }}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign In
        </Button>
        <Button
          sx={{ marginTop: 1 }}
          fullWidth
          size="small"
          color="inherit"
          variant="text"
          className={classes.submit}
          onClick={() => dispatch(forgotPassword(username))}
        >
          Forgot password
        </Button>
      </div>
    </React.Fragment>
  );
};

const Challenge = function ({ challenge }: { challenge: AuthChallenge }) {
  if (challenge.name === "FORGOT_PASSWORD") {
    return <ForgotPasswordComponent username={challenge.user?.username} />;
  } else if (challenge.name === "NEW_PASSWORD_REQUIRED") {
    return <NewPasswordRequiredComponent username={challenge.user?.username} />;
  } else {
    return null;
  }
};

export const AuthComponent = function (props: any) {
  const theme = useTheme()
  const dispatch = useDispatch();
  const { version, challenge, loggedIn, authReady } = useSelector(
    (state: State) => state.app
  );
  const { message } = useSelector((state: State) => state.ui);
  const classes = useStyles(theme)

  useEffect(() => {
    dispatch(initAmplify());
    dispatch(initAuth());
  }, [dispatch]);

  if (!authReady) {
    return <div></div>;
  }

  if (loggedIn) {
    return props.children;
  }
  return (
    <Grid container component="main" className={classes.root}>
      <Dialog
        open={Boolean(message)}
        onClose={() => dispatch(hideMessage())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message?.title}</DialogTitle>
        <DialogContent>
          <Typography>{message?.text}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => dispatch(hideMessage())}
            variant="outlined"
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {challenge === null ? (
            <SignInComponent />
          ) : (
            <Challenge challenge={challenge} />
          )}
        </div>
        <Box mt={5}>
          <Copyright version={version} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignInComponent;
