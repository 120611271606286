import { Environment } from '../model/Environment';
import { ImportJobDetail, ImportJobSimple, ImportTask } from '../model/ImportJob';
import { ImportJobFilter } from '../model/ImportJobFilter';
import { ListResponse, SingleResponse } from '../model/ListResponse';
import { Observation } from '../model/Observation';
import { Observer } from '../model/Observer'
import { Season } from '../model/Season';
import { Survey } from '../model/Survey';
import { SurveyFilter } from '../model/SurveyFilter';
import { UploadUrlResponse } from '../model/UploadUrl';
import { del, get, getText, put, queryOptionsToParams } from './fetchUtil'

export interface Geometry {
    type: string;
    coordinates: number[];
}

export interface Feature {
    type: string;
    geometry: Geometry;
    properties: Record<string, any>;
}

export interface FeatureCollection {
    type: string;
    features: Feature[]
}

class SurveyApi {
    baseUrl: string;
    token: string | null;
    constructor() {
        this.baseUrl = ''
        this.token = null
    }

    setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    setToken(token: string | null) {
        this.token = token
    }

    async getVersion() : Promise<string> {
        return getText(this.baseUrl, '/api/version', null, () => this.token)
    }

    async loadSurveys(filter: SurveyFilter) : Promise<ListResponse<any>> {        
        return get(this.baseUrl, '/api/survey', filter, () => this.token)
    }
    
    async loadSurvey(id: string) : Promise<SingleResponse<Survey>> {
        return get(this.baseUrl, `/api/survey/${id}`, null, () => this.token)
    }

    async updateSurvey(id: string, patch: Record<string, any>) : Promise<SingleResponse<Survey>> {
        return put(this.baseUrl, `/api/survey/${id}`, patch, () => this.token)
    }

    async approveSurvey(id: string) : Promise<SingleResponse<Survey>> {
        return put(this.baseUrl, `/api/survey/${id}/approve`, null, () => this.token)
    }

    async deleteSurvey(id: string) : Promise<void> {
        return del(this.baseUrl, `/api/survey/${encodeURIComponent(id)}`, () => this.token)
    }

    async loadImportJobs(filter: ImportJobFilter) : Promise<ListResponse<ImportJobSimple>> {        
        const f = {
            dataLocationSearch: filter.dataLocationSearch,
            season: filter.season,
            statuses: filter.statuses ? filter.statuses.join(',') : ''
        }
        return get(this.baseUrl, "/api/importjob", f, () => this.token)
    }

    async loadImportJobDetails(id: string) : Promise<ImportJobDetail> {
        return get(this.baseUrl, `/api/importjob/${id}`, null, () => this.token)
    }

    async loadImportTasks(id: string) : Promise<ListResponse<ImportTask>> {
        return get(this.baseUrl, `/api/importjob/${id}/task`, null, () => this.token)
    }

    async resetImportJob(id: string) : Promise<any> {
        return del(this.baseUrl, `/api/importjob/${id}`, () => this.token)
    }
    
    async deleteImportJob(id: string) : Promise<any> {
        const params = queryOptionsToParams({ "mode": "delete" });
        return del(this.baseUrl, `/api/importjob/${id}${params}`, () => this.token)
    }

    async loadObservers() : Promise<ListResponse<Observer>> {
        return get(this.baseUrl, "/api/observer", null, () => this.token)
    }    

    async loadTracklogPage(surveyId: string, page: number, pageSize: number) : Promise<FeatureCollection> {
        return get(this.baseUrl, `/api/survey/${surveyId}/tracklog`, { srid: '4326', page, pageSize }, () => this.token)        
    }

    async loadEnvironment(surveyId: number) : Promise<ListResponse<Environment>> {
        return get(this.baseUrl, `/api/survey/${surveyId}/environment`, null, () => this.token)
    }
    async loadObservationsPage(surveyId: number, page: number, pageSize: number) : Promise<ListResponse<Observation>> {
        return await get(this.baseUrl, `/api/survey/${surveyId}/observation`, { page, pageSize }, () => this.token)        
    }
    
    async updateObservation(surveyId: string, id: string, patch: Record<string, any>) : Promise<SingleResponse<Observation>> {
        //console.log('hello put', id)
        return put(this.baseUrl, `/api/survey/${surveyId}/observation/${id}`, patch, () => this.token)
    }

    async loadTransectLines(surveyId: string) : Promise<ListResponse<any>> {
        return await get(this.baseUrl, `/api/survey/${surveyId}/transectlines`, null, () => this.token)        
    }

    async loadSeasons() : Promise<ListResponse<Season>> {
        return await get(this.baseUrl, `/api/season`, null, () => this.token)
    }

    async getUploadUrl(season: number, surveyName: string, filename: string) : Promise<UploadUrlResponse> {
        const _season = encodeURIComponent(season)
        const _surveyName = encodeURIComponent(surveyName)
        return await get(this.baseUrl, `/api/upload/${_season}/${_surveyName}`, { filename }, () => this.token)
    }

    async uploadData(url: string, data: ArrayBuffer | string) : Promise<void> {
        await fetch(url, {
            method: "PUT",
            body: data
          });
    }

}
export default new SurveyApi()