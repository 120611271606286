import {
    applyMiddleware,
    createStore,
    compose
} from "redux";
import rootSaga from './sagas/rootSaga'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'   
import amplifyBridge from './amplify/amplifyBridge'
import { Hub } from 'aws-amplify';
import { OBSERVATION_APPEND } from "./survey/observations/observationActions";
import { TRACKLOG_APPEND } from "./survey/tracklog/tracklogActions";

const actionSanitizer = (action : any) => {
    switch (action.type) {
        case OBSERVATION_APPEND:
            return { ...action, payload: action.payload?.length }
        case TRACKLOG_APPEND:
            return { ...action, payload: action.payload?.length  }
        default:
            return action
    } 
}

const stateSanitizer = (state : any) => {
    return { ...state, surveyPage: {
        ...state.surveyPage, 
        observations: state.surveyPage.observations?.length,
        tracklog: state.surveyPage.tracklog && { featureCollection: state.surveyPage.tracklog.features.length },
    }}
}

const reduxDevtoolsExtensionOptions = {
    actionSanitizer,
    stateSanitizer
};

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevtoolsExtensionOptions)) ||
compose;

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    reducers,
    composeEnhancers(
        actionSanitizer,
        applyMiddleware(sagaMiddleware),
    )
);

const amplifyListener = amplifyBridge(store.dispatch)
Hub.listen('auth', amplifyListener);


sagaMiddleware.run(rootSaga)

export default store;