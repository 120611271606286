import { ReduxAction } from "../actionModel";
import { UI_CANCEL_ACTION, UI_CONFIRM_ACTION, UI_HIDE_MESSAGE, UI_LOADING, UI_SHOW_ACTION_BOX, UI_SHOW_MESSAGE, UI_STOP_LOADING } from "./uiActions";

export interface UiMessage {
  title: string;
  text: string;
}

export interface UiStore {
  loading: boolean;
  message: UiMessage | null;
  confirm: {
    message: UiMessage,
    action: ReduxAction
  } | null
}

const initialState: UiStore = {
  loading: false,
  message: null,
  confirm: null
};

const uiStore = (state: UiStore = initialState, action: any): UiStore => {
  switch (action.type) {
    case UI_LOADING:
      return { ...state, loading: true };
    case UI_STOP_LOADING:
      return { ...state, loading: false };
    case UI_SHOW_MESSAGE:
      return { ...state, message: action.payload }
    case UI_HIDE_MESSAGE:
      return { ...state, message: null }
    case UI_SHOW_ACTION_BOX:
      return { ...state, confirm: action.payload }
    case UI_CANCEL_ACTION:
      return { ...state, confirm: null }
    case UI_CONFIRM_ACTION:
      return { ...state, confirm: null }
    default:
      return state;
  }
};

export default uiStore;
