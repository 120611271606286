import {
  delay,
  put,
  race,
  take,
  takeLeading,
} from "@redux-saga/core/effects";
import surveyApi from "../../service/surveyApi";
import { apiRequest } from "../api/apiRequest";
import {
  importTaskListEndWatch,
  ImportTaskListInitAction,
  importTaskListStartWatch,
  IMPORTTASK_LIST_ENDWATCH,
  IMPORTTASK_LIST_INIT,
  IMPORTTASK_LIST_STARTWATCH,
  setImportJobDetail,
} from "./importTaskListActions";
import { ImportJobDetail } from "../../model/ImportJob";
import { showMessage } from "../ui/uiActions";

function* processImportTaskListInit(action: ImportTaskListInitAction) {
  try {
    const { id } = action.payload;
    yield apiRequest(surveyApi, surveyApi.loadImportJobDetails, [id], {
      "200": (data: ImportJobDetail) => setImportJobDetail(data),
      error: (error: any) =>
        showMessage({
          title: "API Fejl",
          text: "Kunne ikke hente import tasks",
        }),
    });
    yield put(importTaskListStartWatch(id));
  } catch (error) {}
}

/* Worker Function */
function* pollImportJobDetails(action: ImportTaskListInitAction) {
  while (true) {
    try {
      const id = action.payload.id;
      yield apiRequest(surveyApi, surveyApi.loadImportJobDetails, [id], {
        "200": (data: ImportJobDetail) => setImportJobDetail(data),
        error: (error: any) =>
          showMessage({
            title: "API Fejl",
            text: "Kunne ikke hente import tasks",
          }),
      });
      yield delay(2000);
    } catch (err) {
      yield put(importTaskListEndWatch());
    }
  }
}

/* Watcher Function */
function* pollTaskWatcher<E>(startAction : string, endAction: string, pollTask: (action : E) => void) {
  while (true) {
    const action: E = yield take(
        startAction
    );
    yield race([pollTask(action), take(endAction)]);
  }
}

export default function* watcher() {
  yield takeLeading(IMPORTTASK_LIST_INIT, processImportTaskListInit);
  yield pollTaskWatcher(IMPORTTASK_LIST_STARTWATCH, IMPORTTASK_LIST_ENDWATCH, pollImportJobDetails);
}
