import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { observationsNextPage, observationsPrevPage, observationFilterChanged, requestUpdateObservation } from "../../redux/survey/observations/observationActions";
import { State } from "../../redux/reducers";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import StaticDataList, {
  EditRowButton,
  TextFieldCell,
  UnixTimeOnlyCell,
} from "../dataList/StaticDataList";
import { 
  LinearProgress,
  IconButton, 
  Dialog, 
  DialogContent, 
  DialogTitle 
} from "@mui/material";
import { Observation, ObservationFilter } from "../../model/Observation";
import DelayedTextField from "../DelayedTextField";
import EditComponent, { TextFieldEdit, DateTimeFieldEdit } from "../EditComponent";
import produce from "immer";

interface ObservationFilterProps {
    filter: ObservationFilter,
    onChange: (filter: ObservationFilter) => {}
}

interface ObservationsListComponentType {
    visible?: boolean,
    loading?: boolean,
    selectedObservation: number | null,
    observations: Observation[],
    onSelect: (item: Observation) => void
}

const ObservationFilterComponent = (props: ObservationFilterProps) => {
    const { onChange, filter } = props
    return (
        <div>
            <DelayedTextField size='small' delay={350} label='Min count' type='number' value={filter.minCount} onChange={(value: any) => onChange({ minCount: value, speciesId: filter.speciesId})} />
            <DelayedTextField size='small' delay={350} label='Species ID' value={filter.speciesId || ''} onChange={(value: any) => onChange({ minCount: filter.minCount, speciesId: value || null})} />
        </div>
    )
}

const ObservationsListComponent = (props: ObservationsListComponentType) => {
  const dispatch = useDispatch();
  const [ editObservation, setEditObservation ] = useState<Observation | null>(null)    
  const { filter, currentPage, pageSize } = useSelector((state: State) => state.observations);
  const { selectedObservation, onSelect, observations, visible } = props;
  const loading = Boolean(props.loading)
  if (observations == null) {
    return null;
  }
  const obsOffset = (currentPage - 1) * pageSize;
  const pages = ~~((observations.length - 1) / pageSize) + 1
  return (
    <React.Fragment>
        <Dialog
          open={Boolean(editObservation)}
          onClose={() => setEditObservation(null)}>
          <DialogTitle>Rediger observation</DialogTitle>
          <DialogContent>
            <EditComponent
                elevation={0}
                item={editObservation} 
                save 
                cancel
                dirty={true} 
                onChange={
                  (source: keyof Observation, value: any) => { 
                    setEditObservation(produce(editObservation, (draft: any) => { draft[source] = value}))
                  }
                }
                onSave={() => { if (editObservation !== null) { dispatch(requestUpdateObservation(editObservation.surveyId, editObservation.id, editObservation)); setEditObservation(null) }}}
                onCancel={() => setEditObservation(null)}
            >
              <TextFieldEdit<Observation> title='Id' source='id' readonly />      
              <DateTimeFieldEdit readonly title='Tid' source='timestampms' unit='ms' />        
              <TextFieldEdit<Observation>  title='Antal' source='count' />
              <TextFieldEdit<Observation>  title='Species ID' source='speciesId' />
              <TextFieldEdit<Observation>  title='Distband' source='distBand' />
            </EditComponent>
          </DialogContent>
      </Dialog>
        { loading && <div style={{ marginBottom: 16 }}><LinearProgress /></div>}
        <ObservationFilterComponent filter={filter} onChange={(filter) => dispatch(observationFilterChanged(filter))} />
        <StaticDataList
        dense
        visible={visible}
        loading={false}
        highlight={(item) => item.id === selectedObservation}
        onClick={(item) => onSelect(item)}
        offset={obsOffset}
        limit={pageSize}
        items={observations}>
            <EditRowButton title={'Edit'} source={''} onClick={(item: Observation) => setEditObservation(produce(item, (draft: any) => draft))} />
            <UnixTimeOnlyCell title={"Tid"} source={"timestampms"} unit="ms" />
            <TextFieldCell title={"Type"} source={"observationType"} />
            <TextFieldCell title={"Count"} source={"count"} />
            <TextFieldCell title={"SpeciesId"} source={"speciesId"} />
            <TextFieldCell title={"Bånd"} source={"distBand"} />
            <TextFieldCell title={"Alder"} source={"age"} />
            <TextFieldCell title={"Adfærd"} source={"behaviour"} />
            <TextFieldCell title={"Køn"} source={"sex"} />
            <TextFieldCell title={"Observatør"} source={"observerInitials"} />
            <TextFieldCell title={"Side"} source={"observerSeat"} />
            <TextFieldCell title={"Id"} source={"id"} />
        </StaticDataList>
        <IconButton onClick={() => dispatch(observationsPrevPage())}><ChevronLeftIcon /></IconButton>
        {` ${currentPage} / ${pages}`}
        <IconButton onClick={() => dispatch(observationsNextPage())}><ChevronRightIcon /></IconButton>
    </React.Fragment>
  );
};

export default ObservationsListComponent;
