import { ReduxAction } from "../actionModel";
import { UploadFile, UploadStatus } from "./uploadReducer";

export const UPLOAD_INIT = "[upload] init";
export const UPLOAD_SET_FILES = "[upload] set files";
export const UPLOAD_UPDATE_FILE_STATUS = "[upload] update file status";
export const UPLOAD_SET_PROGRESS = "[upload] set progress"
export const UPLOAD_RESET_PROGRESS = "[upload] reset progress"
export const UPLOAD_STARTED = "[upload] started"
export const UPLOAD_COMPLETE = "[upload] complete"

export interface UpdateFileStatusAction {
  type: string;
  payload: {
    name: string;
    status: UploadStatus;
  };
}

export interface SetFilesAction {
  type: string;
  payload: {
    files: UploadFile[];
  };
}

export interface SetUploadProgressAction {
  type: string;
  payload: {
    complete: number;
    total: number;
  }
}

export interface UploadAction {
  type: string
}

export const setUploadFiles = (files: UploadFile[]): SetFilesAction => ({
  type: UPLOAD_SET_FILES,
  payload: { files },
});

export const updateFileStatus = (name : string, status: UploadStatus) : UpdateFileStatusAction => ({
  type: UPLOAD_UPDATE_FILE_STATUS,
  payload: {
    name, status
  }
})

export const uploadStarted = () : UploadAction => ({
  type: UPLOAD_STARTED
})

export const uploadComplete = () : UploadAction => ({
  type: UPLOAD_COMPLETE
})

export const setUploadProgress = (complete: number, total: number) : SetUploadProgressAction => ({
  type: UPLOAD_SET_PROGRESS,
  payload: {
    complete, total
  }
})

export const resetUploadProgress = () : ReduxAction => ({
  type: UPLOAD_RESET_PROGRESS,
})