import { useState } from "react";
import {
  Button,
  LinearProgress,
  Box,
  Grid,
  TextField,
  Typography,
  Modal,
  Paper,
} from "@mui/material";
import { UploadFile } from "../redux/upload/uploadReducer";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../redux/reducers";
import surveyApi from "../service/surveyApi";
import { AppDispatch } from "..";
import {
  resetUploadProgress,
  setUploadFiles,
  updateFileStatus,
  uploadComplete,
  uploadStarted,
} from "../redux/upload/uploadActions";
import StaticDataList, {
  ConditionalCell,
  ConditionEquals,
  FileSizeCell,
  TextFieldCell,
} from "../components/dataList/StaticDataList";
import UploadButton from "../components/upload/UploadButton";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from '@mui/icons-material/Done';
import PendingOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'

const valid = (season: number, surveyName: string): boolean => {
  if (!season || season < 1900) {
    return false;
  }
  return Boolean(surveyName);
};

const readFile = (file: File): Promise<ArrayBuffer | string | null> => {
  const promise: Promise<ArrayBuffer | string | null> = new Promise(
    (resolve, reject) => {
      const fr = new FileReader();
      fr.onload = function (e) {
        // binary data
        if (!e || !e.target) {
          return;
        }
        resolve(e.target.result);
      };
      fr.onerror = function (e) {
        reject(e);
      };
      fr.readAsArrayBuffer(file);
    }
  );
  return promise;
};

const handleChange = async (
  season: number,
  surveyName: string,
  filelist: FileList,
  dispatch: AppDispatch
): Promise<void> => {
  if (!filelist) {
    console.log("filelist null");
    return;
  }
  const files: File[] = [];
  for (let i = 0; i < filelist.length; i++) {
    const file: File | null = filelist && filelist[i];
    if (file === null) {
      continue;
    }
    files.push(file);
  }
  if (files.length === 0) {
    console.log("no files selected");
    return;
  }
  dispatch(uploadStarted());
  dispatch(
    setUploadFiles(
      files.map(
        (f: File): UploadFile => ({
          name: f.name,
          status: "pending",
          size: f.size,
        })
      )
    )
  );
  for (let i = 0; i < files.length; i++) {
    const file: File = files[i];
    console.log({ file: file.name });
    const data = await readFile(file);
    if (!data) {
      console.log({ file: file.name, data });
      continue;
    }
    dispatch(updateFileStatus(file.name, "in_progress"));
    const urlResponse = await surveyApi.getUploadUrl(
      season,
      surveyName,
      file.name
    );
    console.log({ urlResponse });
    try {
      await fetch(urlResponse.url, {
        method: "PUT",
        body: data,
      });
      dispatch(updateFileStatus(file.name, "ok"));
    } catch {
      dispatch(updateFileStatus(file.name, "failed"));
    }
  }
  dispatch(uploadComplete());
};

const UploadPage = () => {
  const dispatch = useDispatch();
  const { progress, uploading, files } = useSelector(
    (state: State) => state.upload
  );
  const [season, setSeason] = useState(0);
  const [surveyName, setSurveyName] = useState("");

  return (
    <>
      <Modal
        open={progress !== null}
        sx={{ margin: "auto", marginTop: 8, padding: 2, maxWidth: 800 }}
        onClose={() => dispatch(resetUploadProgress())}
      >
        <Paper sx={{ padding: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4'>
                Uploading
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StaticDataList items={files}>
                <TextFieldCell title="Name" source="name" />
                <FileSizeCell title="Size" source="size" />
                <ConditionalCell minWidth={120} title="Status" source="status">
                  <ConditionEquals case='ok'>
                    <DoneIcon color='success'  fontSize='small'/>
                  </ConditionEquals>
                  <ConditionEquals case='pending'>
                    <PendingOutlinedIcon color='inherit' fontSize='small'/>
                  </ConditionEquals>
                  <ConditionEquals case='in_progress'>
                    <CircularProgress variant='indeterminate' size='1.25rem' />
                  </ConditionEquals>
                  <ConditionEquals case='failed'>
                    <span>Fejl</span>
                  </ConditionEquals>
                </ConditionalCell>
              </StaticDataList>
            </Grid>
            <Grid item xs={12}>
              {progress && progress.total > 0 ? (
                <LinearProgress
                  sx={{ height: 8 }}
                  variant="determinate"
                  value={(progress.complete * 100) / progress.total}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Button 
                onClick={() => dispatch(resetUploadProgress())}
                disabled={!progress || progress.complete < progress.total} fullWidth variant='contained'>Close</Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <Box sx={{ marginTop: 2 }}>
        <Typography sx={{ marginBottom: 2 }} variant="h3">
          Upload
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type="number"
              value={season}
              label="Sæson"
              onChange={(evt) => setSeason(parseInt(evt.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={surveyName}
              label="Navn"
              onChange={(evt) => setSurveyName(evt.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadButton
              label='Upload files'
              disabled={uploading || !valid(season, surveyName)}
              onChange={(evt: any, filelist: (FileList | null)) => {
                //const filelist: FileList | null = evt.target.files;
                console.log({ filelist });
                if (!filelist) {
                  dispatch(setUploadFiles([]))
                  return;
                }
                handleChange(
                  season,
                  surveyName,
                  filelist as FileList,
                  dispatch
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UploadPage;
