import { ImportJobSimple } from "../../model/ImportJob";
import { ImportJobFilter } from "../../model/ImportJobFilter";
import { ReduxAction } from "../actionModel";

export const IMPORTJOB_INIT = "[importjob] init";
export const IMPORTJOB_SET_FILTER = "[importjob] set filter";
export const FETCH_IMPORTJOBS: string = "[importjob] fetch";
export const FETCH_IMPORTJOBS_INIT: string = "[importjob] fetch init";
export const FETCH_IMPORTJOBS_DONE: string = "[importjob] fetch done";
export const IMPORTJOB_SET_IMPORTJOBS = "[importjob] set importjobs";

export const IMPORTJOB_AUTOREFRESH_ON = "[importjob] autorefresh on";
export const IMPORTJOB_AUTOREFRESH_OFF = "[importjob] autorefresh off";

export const IMPORTJOB_RESET: string = "[importjob] reset job";
export const IMPORTJOB_DELETE: string = "[importjob] delete job";

export interface SetImportJobFilterAction {
  type: string;
  payload: ImportJobFilter;
}

export interface SetImportJobsAction {
  type: string;
  payload: ImportJobSimple[];
}

export interface ImportJobResetAction {
  type: string;
  payload: string;
}

export const setImportJobFilter = (
  filter: ImportJobFilter
): SetImportJobFilterAction => ({
  type: IMPORTJOB_SET_FILTER,
  payload: filter,
});

export const fetchImportJobs = (
  filter: ImportJobFilter
): SetImportJobFilterAction => ({
  type: FETCH_IMPORTJOBS,
  payload: filter,
});

export const setImportJobs = (
  importjobs: ImportJobSimple[]
): SetImportJobsAction => ({
  type: IMPORTJOB_SET_IMPORTJOBS,
  payload: importjobs,
});

export const initImportJobPage = (): any => ({
  type: IMPORTJOB_INIT,
});

export const resetImportJob = (id: string): any => ({
  type: IMPORTJOB_RESET,
  payload: id,
});

export const deleteImportJob = (id: string): any => ({
  type: IMPORTJOB_DELETE,
  payload: id,
});

export const setAutoRefresh = (state: boolean): ReduxAction => {
  const type = state ? IMPORTJOB_AUTOREFRESH_ON : IMPORTJOB_AUTOREFRESH_OFF;
  return {
    type
  };
};
