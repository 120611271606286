import { ReduxAction } from "../actionModel";

type RunSingleEnum = '[jobtrigger] trigger run single'

export const JOBTRIGGER_SCAN_SURVEYS = "[jobtrigger] trigger scan surveys"
export const JOBTRIGGER_SCAN_LAYERS = "[jobtrigger] trigger scan layers"
export const JOBTRIGGER_RUN_ALL = "[jobtrigger] trigger run all"
export const JOBTRIGGER_RUN_SINGLE = "[jobtrigger] trigger run single"


export interface JobTriggerRunSingleAction {
    type: RunSingleEnum;
    payload: {
        jobId: number;
    }
}


export const triggerScanSurveys = () : ReduxAction => ({
    type: JOBTRIGGER_SCAN_SURVEYS    
})

export const triggerScanLayers = () : ReduxAction => ({
    type: JOBTRIGGER_SCAN_LAYERS    
})

export const triggerRunAllJobs = () : ReduxAction => ({
    type: JOBTRIGGER_RUN_ALL
})

export const triggerRunSingle = (jobId: number) : JobTriggerRunSingleAction => ({
    type: JOBTRIGGER_RUN_SINGLE,
    payload: { jobId }
})

