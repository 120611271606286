import { CognitoUser, ChallengeName } from 'amazon-cognito-identity-js';
import { 
    takeEvery,
    takeLeading, 
    apply, 
    put,
    select
} from "@redux-saga/core/effects";

import { 
    APP_REQUEST_LOGIN, 
    RequestLoginAction, 
    setUser,
    setLoggedOut,
    APP_REQUEST_LOGOUT,
    APP_INIT_AUTH,
    SignInAmplifyResponse,
    setChallenge,
    APP_REQUEST_NEW_PASSWORD,
    RequestNewPasswordAction,
    APP_FORGOT_PASSWORD,
    ForgotPasswordSubmitAction,
    APP_FORGOT_PASSWORD_SUBMIT,
    requestLogin,
    requestLogout,    
    APP_LOGIN_COMPLETE,
    loginComplete,
    APP_INIT,
    setApiVersion
} from './appActions'
import { Auth } from "aws-amplify";
import { User } from "./appReducer";
import surveyApi from "../../service/surveyApi";
import { State } from '../reducers';
import { showMessage } from '../ui/uiActions';
import { AUTH_ERROR } from '../api/apiActions';
import { Base64 } from 'js-base64';
import { ReduxAction } from '../actionModel';
import { apiRequest } from '../api/apiRequest';
import jobTriggerApi from '../../service/jobTriggerApi';

// https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#password-operations



function* processInit(action : ReduxAction) {
    try { 
        const version : string = yield apiRequest(surveyApi, surveyApi.getVersion, [], {
            "error": showMessage({ title: "API Fejl", text: "Kunne ikke hente api version"})
        })        
        yield put(setApiVersion(version))
        console.log('got version', version)
    } catch (error ) {
        console.log(error)
    }
}

function* processRequestLogin(action : RequestLoginAction) {
    try {
        const { username, password } = action.payload
        const rawuser : SignInAmplifyResponse = yield apply(Auth, Auth.signIn, [ username, password ]);
        
        const newPasswordChallenge : ChallengeName = 'NEW_PASSWORD_REQUIRED'

        if (rawuser.challengeName === newPasswordChallenge) {
            yield put(setChallenge(rawuser, rawuser.challengeName, rawuser.challengeParam))
            return
        } else {
            yield put(loginComplete())
        }
    } catch (error : any) {
        try {  
            if (error && error.message) {          
                yield put(showMessage({ title: 'Error', text: '' + error.message }))
            } else {
                console.log(error)
            }
            yield put(setLoggedOut())
        } catch (error) {}
    }
}

function* processLoginComplete(action : ReduxAction) {
    try {
        // @ts-ignore
        const rawuser : any = yield Auth.currentAuthenticatedUser();
        const ntoken = rawuser.getSignInUserSession()?.getIdToken().getJwtToken();
        if (!ntoken) {
            return
        }
        const token = ntoken
        const jwt = parseJwt(token)
        const groups : string[] | undefined = jwt && jwt["cognito:groups"]    
        const user : User = {
            username: rawuser.getUsername(),
            token: token,
            groups
        }        
        surveyApi.setToken(user.token as string)
        jobTriggerApi.setToken(user.token as string)
        yield put(setUser(user))  
    } catch (error : any) {
        try {  
            if (error && error.message) {          
                yield put(showMessage({ title: 'Error', text: '' + error.message }))
            } else {
                console.log(error)
            }
            yield put(setLoggedOut())
        } catch (error) {}
    }
}

function* processRequestNewPassword(action : RequestNewPasswordAction) {
    try {
        const { name, password } = action.payload
        const user : SignInAmplifyResponse = yield select((state: State) => state.app.challenge?.user )
        const rawuser : SignInAmplifyResponse = yield apply(Auth, Auth.completeNewPassword, [ user, password, { name } ])
        const _user : User = {
            username: rawuser.getUsername(),
            token: rawuser.getSignInUserSession()?.getIdToken().getJwtToken()
        }        
        yield put(setUser(_user))
        surveyApi.setToken(_user.token as string)
        jobTriggerApi.setToken(_user.token as string)
    } catch (error) {
        showMessage({ title: 'Fejl', text: '' + error})
        yield put(setLoggedOut())
    }
}

function* processForgotPassword(action: any) {
    try {
        console.log('forgot password saga')
        const username = action.payload as string
        if (!username || username === '') {
            yield put(showMessage({ title: 'Username required', text: 'Please enter username and try again'}))
            return
        }
        const data : Record<string, any> = yield apply(Auth, Auth.forgotPassword, [ username ])
        yield put(setChallenge({ username }, 'FORGOT_PASSWORD', null))
        console.log(data)
    } catch (error) {
        try {
            yield put(showMessage({ title: 'Fejl', text: '' + error}))
        } catch (error) {
            console.error(error)
        }
    }
}

function* processForgotPasswordSubmit(action: ForgotPasswordSubmitAction) {
    try {
        const {username, code, password } = action.payload
        const data : Record<string, any> = yield apply(Auth, Auth.forgotPasswordSubmit, [ username, code, password ])
        yield put(requestLogin(username, password))
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

const parseJwt = function(jwtToken : string) : any {
    try {
        const tokenContent = jwtToken.split('.')[1]
        return JSON.parse(Base64.decode(tokenContent))
    } catch (error) {
        console.log('error parsing jwtToken', jwtToken, error)
        return null
    }
    
}

function* processInitAuth(action : any) {
    try {
        const rawuser : CognitoUser | null | undefined = yield apply(Auth, Auth.currentAuthenticatedUser, []);
        if (!rawuser) {
            yield put(setLoggedOut())
            return
        }
        const tokenNullable = rawuser.getSignInUserSession()?.getIdToken().getJwtToken()
        if (!tokenNullable) {
            yield put(setLoggedOut())
            return
        }
        const token = tokenNullable
        const jwt = parseJwt(token)
        const groups : string[] | undefined = jwt && jwt["cognito:groups"]       
        const user : User = {
            username: rawuser?.getUsername(),
            token: token,
            groups
        }
        yield put(setUser(user))
        surveyApi.setToken(user.token as string)
        jobTriggerApi.setToken(user.token as string)
    } catch (error) {
        console.log(error)
        yield put(setLoggedOut())
    }
}

function* processRequestLogout(action : any) {
    try {        
        yield apply(Auth, Auth.signOut, []);
        surveyApi.setToken(null)
        jobTriggerApi.setToken(null)
        yield put(setLoggedOut())
        //console.log(rawuser.getSignInUserSession()?.getIdToken().getJwtToken())
        //console.log("user", rawuser);
        //console.log(Auth.currentAuthenticatedUser);
    } catch (error) {
        console.log(error)
        yield put(setLoggedOut())
    }
}


function* processAuthError(action: any) {
    try {
        yield put(showMessage({ title: 'Auth error', text: 'Authentication failed' }))
        yield put(requestLogout())
    } catch (error) {
        console.log(error)
    }
}

export default function* watcher() {
    yield takeLeading(APP_INIT, processInit)
    yield takeEvery(AUTH_ERROR, processAuthError)
    yield takeLeading(APP_REQUEST_LOGIN, processRequestLogin)
    yield takeLeading(APP_LOGIN_COMPLETE, processLoginComplete)
    yield takeLeading(APP_REQUEST_NEW_PASSWORD, processRequestNewPassword)
    yield takeLeading(APP_REQUEST_LOGOUT, processRequestLogout)
    yield takeLeading(APP_INIT_AUTH, processInitAuth)
    yield takeLeading(APP_FORGOT_PASSWORD, processForgotPassword)
    yield takeLeading(APP_FORGOT_PASSWORD_SUBMIT, processForgotPasswordSubmit)
}