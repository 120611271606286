import { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux";
import {
  resetImportJob,
  deleteImportJob
} from "../redux/importjob/importJobActions";
import { State } from "../redux/reducers";
import { 
  Paper,
  Typography,
  Button, 
  Grid
} from "@mui/material";
import { importTaskListEndWatch, importTaskListInit } from "../redux/importTaskList/importTaskListActions";
import StaticDataList, { UnixTimeOnlyCell, TextFieldCell, UnixDateOnlyCell } from "../components/dataList/StaticDataList";
import { ImportStatusType } from "../model/ImportJob";
import UploadComponent from "../components/upload/UploadComponent";

const ImportTaskListPage = (props: any) => {
  const { id } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { job } = useSelector((state: State) => state.importTaskList)
  const jobStatus : (ImportStatusType | null) = job?.status.toLocaleLowerCase() as ImportStatusType || null
  useEffect(() => {
    if (id === null || typeof(id) === 'undefined') {
      throw new Error("Nullpointer exception")
    }
    dispatch(importTaskListInit(id))
    return () => {
      dispatch(importTaskListEndWatch())
    }
  }, [ dispatch, id ])
  
  if (id === null || typeof(id) === 'undefined') {
    throw new Error("Nullpointer exception")
  }
  if (!job) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ marginTop: 2, padding: 1, paddingLeft: 2}}>
      <Typography variant='body1'>
          Id: {job.id}
        </Typography>
        <Typography variant='body1'>
          Data location: {job.dataLocation}
        </Typography>
        <Typography variant='body1'>
          Survey ID: {job.surveyId}
        </Typography>
        <Typography variant='body1'>
          Status: {job.status}
        </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <StaticDataList items={job.tasks || []}>
          <TextFieldCell title={"Type"} source={"type"} />
          <UnixDateOnlyCell title={"Dato"} source={"started"} unit="ms" />
          <UnixTimeOnlyCell title={"Start"} source={"started"} unit="ms" />
          <UnixTimeOnlyCell title={"Slut"} source={"finished"} unit="ms" />
          <TextFieldCell title={"Status"} source={"status"} />
          <TextFieldCell title={"Message"} source={"message"} />
        </StaticDataList>
      </Grid>      
      <Grid item xs={12}>
        <Button disabled={jobStatus === 'aborted'} variant='contained' onClick={() => dispatch(resetImportJob(id))} color='warning'>Retry</Button>
        <Button variant='contained' onClick={() => { navigate('/import'); dispatch(deleteImportJob(id)) }} color='error'>Delete</Button>
        <UploadComponent 
          key={job.id}
          disabled={jobStatus === 'aborted' || jobStatus === 'finished'} 
          season={job.season}
          dataLocation={job.dataLocation} />
      </Grid>
    </Grid>
  );
};

export default ImportTaskListPage;
