import { put, select, takeLeading } from "@redux-saga/core/effects";
import produce from "immer";
import { ListResponse } from "../../model/ListResponse";
import { Season } from "../../model/Season";
import { SurveyFilter } from "../../model/SurveyFilter";
import surveyApi from "../../service/surveyApi";
import { apiRequest, ResponseGenerator } from "../api/apiRequest";
import { setSeasons } from "../app/appActions";
import { showMessage } from '../ui/uiActions'
import { fetchSurveys, SURVEYS_INIT, FETCH_SURVEYS, SetSurveyFilterAction, setSurveys, SURVEYS_SET_FILTER, setSurveyFilter } from "./surveyListActions";


function* processSetFilter(action: SetSurveyFilterAction) {
    try {
        yield put(fetchSurveys(action.payload))
    } catch (error) {
        console.log(error)
    }
}

function* processInitSurveysPage(action: any) {
    try {
        console.log('init surveys')
        const data : ListResponse<Season> = yield apiRequest(surveyApi, surveyApi.loadSeasons, [], {
            "200": (data: ListResponse<Season>) => setSeasons(data.items),
            "error": () => showMessage({ title: "Fejl", text:  "Kunne ikke hente sæsonner" })
        })
        const seasons : Season[] = data.items
        
        let filter : SurveyFilter = yield select((state: any) => state.surveyList.filter)
        if (filter.season === '') {
            if (seasons.length === 0) {
                return
            }
            filter = produce<SurveyFilter>(filter, (draft: SurveyFilter) => {
                draft.season = `${seasons[0].year}`
                return draft
            })
        }
        yield put(setSurveyFilter(filter))
    } catch (error: any) {
        console.log('error', error)
    }
}

function* processFetchSurveys(action: SetSurveyFilterAction) {
    try {
        const data: ResponseGenerator = yield apiRequest(surveyApi, surveyApi.loadSurveys, [action.payload], {
            "200": (data: ListResponse<any>) => setSurveys(data.items),
            "error": () => showMessage({ title: 'Fejl', text: 'Kunne ikke hente surveys' })
        })
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

export default function* watcher() {
    yield takeLeading(SURVEYS_INIT, processInitSurveysPage)
    yield takeLeading(SURVEYS_SET_FILTER, processSetFilter)
    yield takeLeading(FETCH_SURVEYS, processFetchSurveys)
}
