import React, { useEffect, useRef, ReactElement } from "react";
import { Map, MapBrowserEvent } from "ol";
import { OlFeature } from "./OpenLayersMap";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import BaseLayer from "ol/layer/Base";

export interface OlWebGlPointsLayerProps {
    map?: Map;
    animation?: boolean;
    visible: boolean;
    title: string;
    style: any;
    children: ReactElement;
    onFeatureClicked?: (features: OlFeature[]) => void;
}
 

export const createLayer = (title: string, visible: boolean, style: any) : BaseLayer => {
  const layer: BaseLayer = new WebGLPointsLayer({
      style,
      visible,
      disableHitDetection: false,
    });
  //layer.id = title 
  return layer
}

export const createVisibleLayer = (style: any) : BaseLayer => {
  const layer: BaseLayer = new WebGLPointsLayer({
      style,
      visible: true,
      disableHitDetection: false,
    });
  return layer
}

const OlWebGlPointsLayer = (props: OlWebGlPointsLayerProps) => {
    const requestRef = useRef<number>();
  
    const { onFeatureClicked, map, animation, title, visible, style } = props;
    const layerRef = useRef(createLayer(title, visible, style));

    useEffect(() => {
      const layer = layerRef.current
      if (layer) {
        map?.addLayer(layer);
      }
      return () => { map?.removeLayer(layer) }
    }, [map, layerRef]);
   
    useEffect(() => {
      if (!map || !onFeatureClicked) {
        return
      }
      const clickHandler = function (e: MapBrowserEvent<any>) {
        const layer = layerRef.current
          const features: OlFeature[] = [];
          map.forEachFeatureAtPixel(e.pixel, function (feature, _layer: any) {
            if (_layer === layer) {
              features.push(feature);
            }
          });
          if (features.length > 0) {
            onFeatureClicked(features);
          }
        }
        map.on("click", clickHandler);
      return () => map.un("click", clickHandler)
    }, [ onFeatureClicked, layerRef, map])
  
    useEffect(() => {
      if (!layerRef.current) {
          return;
      }
      const layer = layerRef.current
      layer.setVisible(visible);
      layer.changed();
    }, [visible, layerRef]);  
    
    useEffect((): any => {
      if (!map) {
        return;
      }
      if (!animation) {
        return;
      }
      const animationFn = () => {
        const layer = layerRef.current
        layer.changed();
        requestRef.current = requestAnimationFrame(animationFn);
      };
      requestRef.current = requestAnimationFrame(animationFn);
      return () =>
        (requestRef.current || requestRef.current === 0) &&
        cancelAnimationFrame(requestRef.current);
    }, [map,layerRef,animation]);

    const children: ReactElement = props.children;
    return (<div>
       {React.createElement(children.type, { ...children.props, layer: layerRef.current })}
    </div>);
  };
  
  export default OlWebGlPointsLayer