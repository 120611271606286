import { Auth, Logger } from 'aws-amplify';
import { loginComplete } from '../app/appActions';

const logger = new Logger('My-Logger');

const createListener = (dispatch: any) => {
    const listener = (data : any) => {
        console.log(data)
        switch (data.payload.event) {
            case 'configured':
                Auth.currentSession().then(data => console.log(data))                
                break;
            case 'signIn':
                logger.info('user signed in');                
                break;
            case 'signUp':
                logger.info('user signed up');
                break;
            case 'signOut':
                logger.info('user signed out');
                break;
            case 'signIn_failure':
                logger.error('user sign in failed');
                break;
            case 'tokenRefresh':
                logger.info('token refresh succeeded');
                dispatch(loginComplete())
                break;
            case 'tokenRefresh_failure':
                logger.error('token refresh failed');
                break;           
        }
    }
    return listener
}

export default createListener