import { all } from 'redux-saga/effects'

import surveyWatcher from '../survey/surveySagas'
import tracklogWatcher from '../survey/tracklog/tracklogSagas'
import observationWatcher from '../survey/observations/observationSagas'
import environmentWatcher from '../survey/environment/environmentSagas'
import surveyListWatcher from '../surveyList/surveyListSagas'
import importJobWatcher from '../importjob/importJobSagas'
import jobTriggerWatcher from '../importjob/jobTriggerSagas'
import importTaskListWatcher from '../importTaskList/importTaskListSagas'
import uploadWatcher from '../upload/uploadSagas'
import appWatcher from '../app/appSagas'
import apiWatcher from '../api/apiSagas'
import uiWatcher from '../ui/uiSagas'

export default function* rootSaga() {
    yield all([
        surveyWatcher(),
        surveyListWatcher(),
        tracklogWatcher(),
        observationWatcher(),
        environmentWatcher(),
        importJobWatcher(),
        jobTriggerWatcher(),
        importTaskListWatcher(),
        uploadWatcher(),
        appWatcher(),
        apiWatcher(),
        uiWatcher()
    ])
}