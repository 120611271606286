import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SurveyIcon from '@mui/icons-material/AirplanemodeActive';
import ImportIcon from '@mui/icons-material/Publish';
import UploadIcon from '@mui/icons-material/CloudUpload';

import { NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const AutoSelectListItemButton = (props: any) => {
  const location = useLocation()
  const { to, label, icon } = props
  const selected = location.pathname === to 
  return (<ListItemButton selected={selected} component={NavLink}  to={to}>
      <ListItemIcon>
        {icon()}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>)
}

export const mainListItems = (
  <div>
    <AutoSelectListItemButton to="/survey" label='Surveys' icon={() => (<SurveyIcon />)}/>
    <AutoSelectListItemButton to="/import" label='Imports' icon={() => (<ImportIcon />)} />
    <AutoSelectListItemButton to="/upload" label='Upload' icon={() => (<UploadIcon />)} />
  </div>
);

export const secondaryListItems = (
  <div>
   
  </div>
);