import React, { useEffect, useRef, ReactElement } from "react";
import { Map, MapBrowserEvent } from "ol";
import { OlFeature } from "./OpenLayersMap";
import BaseLayer from "ol/layer/Base";
import VectorLayer from "ol/layer/Vector";

export interface OlVectorLayerProps {
    map?: Map;
    visible: boolean;
    style: any;
    children: ReactElement;
    onFeatureClicked?: (features: OlFeature[]) => void;
}
 
export const createLayer = (visible: boolean, style: any) : BaseLayer => {
  const layer: BaseLayer = new VectorLayer({
      style,
      visible
    });  
  return layer
}

export const createVisibleLayer = (style: any) : BaseLayer => {
  return createLayer(true, style);
}

const OlVectorLayer = (props: OlVectorLayerProps) => {
    //const requestRef = useRef<number>();
  
    const { onFeatureClicked, map, visible, style } = props;
    const layerRef = useRef(createLayer(visible, style));

    useEffect(() => {
      const layer = layerRef.current
      if (layer) {
        map?.addLayer(layer);
      }
      return () => { map?.removeLayer(layer) }
    }, [map, layerRef]);
   
    useEffect(() => {
      if (!map || !onFeatureClicked) {
        return
      }
      const clickHandler = function (e: MapBrowserEvent<any>) {
        const layer = layerRef.current
          const features: OlFeature[] = [];
          map.forEachFeatureAtPixel(e.pixel, function (feature, _layer: any) {
            if (_layer === layer) {
              features.push(feature);
            }
          });
          if (features.length > 0) {
            onFeatureClicked(features);
          }
        }
        map.on("click", clickHandler);
      return () => map.un("click", clickHandler)
    }, [ onFeatureClicked, layerRef, map])
  
    useEffect(() => {
      if (!layerRef.current) {
          return;
      }
      const layer = layerRef.current
      layer.setVisible(visible);
      layer.changed();
    }, [visible, layerRef]);  
        
    const children: ReactElement = props.children;
    return (<div>
       {React.createElement(children.type, { ...children.props, layer: layerRef.current })}
    </div>);
  };
  
  export default OlVectorLayer