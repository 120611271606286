import { AmplifyChallengeParam, APP_SET_API_VERSION, APP_SET_CHALLENGE, APP_SET_LOGGED_OUT, APP_SET_SEASONS, APP_SET_USER, SetApiVersionAction, SetChallengeAction, SetSeasonsAction, SetUserAction, SignInAmplifyResponse } from './appActions';
import SurveyApi from '../../service/surveyApi'
import jobTriggerApi from '../../service/jobTriggerApi';
import { Season } from '../../model/Season';

// * global FLYDATA_WEB */
declare var FLYDATA_WEB: any;
if (FLYDATA_WEB) {
    SurveyApi.setBaseUrl(FLYDATA_WEB.url)
    jobTriggerApi.setBaseUrl(FLYDATA_WEB.url)
}

export interface User {
    username?: string;
    token?: string;
    groups?: string[];
}

export interface AuthChallenge {
    user: SignInAmplifyResponse;
    name: string;
    param: AmplifyChallengeParam;
}

export interface AppStore {
    version: string;
    apiVersion: string;
    url: string;
    challenge: AuthChallenge | null;
    loggedIn: boolean;
    user: User | null;
    authReady: boolean;
    seasons: Season[];
  }
  

const initialState : AppStore = {
    version: FLYDATA_WEB.version,
    apiVersion: '',
    url: FLYDATA_WEB.url,
    challenge: null,
    loggedIn: false,
    user: null,
    authReady: false,
    seasons: []
}

const app = (state = initialState, action : any) => {
    switch (action.type) {
        case APP_SET_API_VERSION:
            const setApiVersion : SetApiVersionAction = action
            return { ...state, apiVersion: setApiVersion.payload.version }
        case APP_SET_CHALLENGE:
            const setChallengeAction : SetChallengeAction = action
            return { ...state, user: null, challenge: { user: setChallengeAction.payload.user, name: setChallengeAction.payload.challengeName, param: setChallengeAction.payload.challengeParam }, loggedIn: false }
        case APP_SET_LOGGED_OUT:
            return { ...state, user: null, authReady: true, loggedIn : false, challenge: null }
        case APP_SET_USER:
            const setUserAction : SetUserAction = action
            return { ...state, user: setUserAction.payload, authReady: true, loggedIn: true, challenge: null }
        case APP_SET_SEASONS:
            const setSeasonsAction : SetSeasonsAction = action
            return { ...state, seasons: setSeasonsAction.payload.seasons }
        default:
            return state
    }
}

export default app