import { Action } from "redux"
import { ReduxAction } from "../actionModel"
import { UiMessage } from "./uiReducer"

export const UI_GOTO = "[ui] goto"

export const UI_LOADING = "[ui] loading"
export const UI_STOP_LOADING = "[ui] loading stop"
export const UI_HIDE_MESSAGE = "[ui] hide message"
export const UI_SHOW_MESSAGE = "[ui] show message"

export const UI_SHOW_ACTION_BOX = "[ui] show action box"
export const UI_CONFIRM_ACTION = "[ui] confirm action"
export const UI_CANCEL_ACTION = "[ui] cancel action"

export interface ConfirmActionAction extends ReduxAction {
    payload: { 
        action : Action
    }
}


export interface GotoAction extends ReduxAction {
    payload: { 
        page: string
    }
}

export const loading = () => ({
    type: UI_LOADING
})

export const stopLoading = () => ({
    type: UI_STOP_LOADING
})

export const hideMessage = () => ({
    type: UI_HIDE_MESSAGE
})

export const showMessage = (message: UiMessage) => ({
    type: UI_SHOW_MESSAGE,
    payload: message
})


export const showConfirmActionDialog = (message: UiMessage, action: ReduxAction) => ({
    type: UI_SHOW_ACTION_BOX,
    payload: { 
        action, 
        message
    }
})

export const confirmAction = (action: ReduxAction) : ConfirmActionAction => ({
    type: UI_CONFIRM_ACTION,
    payload: { 
        action
    }
})

export const cancelAction = () : ReduxAction => ({
    type: UI_CANCEL_ACTION,    
})

export const goto = (page: string) : GotoAction => ({
    type: UI_GOTO,
    payload: {
        page
    }
})