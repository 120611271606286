import { put, select, takeLeading } from "@redux-saga/core/effects";
import { ListResponse } from "../../../model/ListResponse";
import surveyApi from "../../../service/surveyApi";
import { apiRequest } from "../../api/apiRequest";
import { showMessage } from '../../ui/uiActions'
import { setEnvironment, environmentSetPage, ENVIRONMENT_PREV_PAGE, ENVIRONMENT_NEXT_PAGE, ENVIRONMENT_INIT, EnvironmentInitAction } from "./environmentActions";
import { State } from "../../reducers";
import { Environment } from "../../../model/Environment";



function* processInitEnvironment(action: EnvironmentInitAction) {
    try {
        const surveyId: number = action.payload.surveyId
        yield apiRequest(surveyApi, surveyApi.loadEnvironment, [ surveyId ], {
            "200": (data: ListResponse<Environment>) => setEnvironment(data.items),
            "error": (error: any) => showMessage({ title: 'API Error', text: 'Kunne ikke hente environment data'})
        })
    } catch (error: any) {
        console.log('error', error)
    }
}

function* processPrevPage(action: any) {
    try {
        const cp: number = yield select((state: State) => state.environment.currentPage)
        if (cp <= 1) {
            yield put(environmentSetPage(1))
            return
        }
        yield put(environmentSetPage(cp - 1))
    } catch (error: any) {
        console.log('error', error)
    }
}

function* processNextPage(action: any) {
    try {
        const { currentPage, pageSize, environment } = yield select((state: State) => state.environment)
        const tp = Math.floor((environment.length - 1) / pageSize) + 1
        const p = currentPage + 1
        if (p > tp) {            
            return
        }
        yield put(environmentSetPage(p))
    } catch (error: any) {
        console.log('error', error)
    }
}


export default function* watcher() {
    yield takeLeading(ENVIRONMENT_INIT, processInitEnvironment)
    yield takeLeading(ENVIRONMENT_PREV_PAGE, processPrevPage)
    yield takeLeading(ENVIRONMENT_NEXT_PAGE, processNextPage)
}
