import { takeLeading } from "@redux-saga/core/effects";
import { apiRequest } from "../api/apiRequest";
import { showMessage } from '../ui/uiActions'
import { ReduxAction } from "../actionModel";
import jobTriggerApi from "../../service/jobTriggerApi";
import { 
    JOBTRIGGER_SCAN_LAYERS, 
    JOBTRIGGER_SCAN_SURVEYS,
    JOBTRIGGER_RUN_ALL,
    JOBTRIGGER_RUN_SINGLE,
    JobTriggerRunSingleAction
} from "./jobTriggerActions";


function* processTriggerScanSurveys(action: ReduxAction) {
    try {
        yield apiRequest(jobTriggerApi, jobTriggerApi.triggerScanSurveys, [], {
            "200": () => showMessage({ title: 'Ok', text: 'Scan surveys påbegyndt' }),
            "error": () => showMessage({ title: 'Fejl', text: 'Scan surveys gav en fejl' })
        })
    } catch (error) {
        console.log(error)
    }
}

function* processTriggerScanLayers(action: ReduxAction) {
    try {
        yield apiRequest(jobTriggerApi, jobTriggerApi.triggerScanLayers, [], {
            "200": () => showMessage({ title: 'Ok', text: 'Scan layers påbegyndt' }),
            "error": () => showMessage({ title: 'Fejl', text: 'Scan layers gav en fejl' })
        })
    } catch (error) {
        console.log(error)
    }
}

function* processTriggerRunJobs(action: ReduxAction) {
    try {
        yield apiRequest(jobTriggerApi, jobTriggerApi.triggerRunAllJobs, [], {
            "200": () => showMessage({ title: 'Ok', text: 'Run all jobs påbegyndt' }),
            "error": () => showMessage({ title: 'Fejl', text: 'Run all jobs gav en fejl' })
        })
    } catch (error) {
        console.log(error)
    }
}

function* processTriggerRunSingle(action: JobTriggerRunSingleAction) {
    try {
        yield apiRequest(jobTriggerApi, jobTriggerApi.triggerRunJob, [ action.payload.jobId ], {
            "200": () => showMessage({ title: 'Ok', text: 'Run all jobs påbegyndt' }),
            "error": () => showMessage({ title: 'Fejl', text: 'Run all jobs gav en fejl' })
        })
    } catch (error) {
        console.log(error)
    }
}

export default function* watcher() {
    yield takeLeading(JOBTRIGGER_SCAN_SURVEYS, processTriggerScanSurveys)    
    yield takeLeading(JOBTRIGGER_SCAN_LAYERS, processTriggerScanLayers)    
    yield takeLeading(JOBTRIGGER_RUN_ALL, processTriggerRunJobs)    
    yield takeLeading(JOBTRIGGER_RUN_SINGLE, processTriggerRunSingle)    
}
