import { put,takeEvery, select } from "@redux-saga/core/effects";
import produce from "immer";
import { State } from "../reducers";
import { showMessage } from "../ui/uiActions";
import { resetUploadProgress, setUploadFiles, setUploadProgress, UpdateFileStatusAction, UPLOAD_UPDATE_FILE_STATUS } from "./uploadActions";

import { UploadFile } from "./uploadReducer";


function* processUpdateFileStatus(action: UpdateFileStatusAction) {
    try {
      const { name, status } = action.payload

        const files : UploadFile[] = yield select((state: State) => state.upload.files)

        const newFiles = files.map((f : UploadFile) : UploadFile => produce(f, (draft: UploadFile) => {
                if (f.name !== name) {
                    return draft
                }
                draft.status = status
                return draft
            })
        )
        yield put(setUploadFiles(newFiles))
        if (newFiles.length === 0) {
          yield put(resetUploadProgress())
        } else {
          yield put(setUploadProgress(newFiles.filter(f => f.status === 'ok').length, newFiles.length))
        }
    } catch (error) {
      yield put(
        showMessage({ title: "Fejl", text: "Uventet fejl i Update File Status" })
      );
    }
  }
  


export default function* watcher() {
    yield takeEvery(UPLOAD_UPDATE_FILE_STATUS, processUpdateFileStatus);
}
