import {
    takeLeading
} from 'redux-saga/effects'

import {
    AUTH_ERROR
} from './apiActions'


function* processAuthError(action) {
    yield console.log('TODO')
}

export default function* watcher() {
    yield takeLeading(AUTH_ERROR, processAuthError)
}
