import FilterHeader, { FilterValue } from "../components/FilterHeader";
import { Tooltip, Grid, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setSurveyFilter,
  initSurveysPage,
} from "../redux/surveyList/surveyListActions";
import { useEffect } from "react";
import { SurveyFilter } from "../model/SurveyFilter";
import StaticDataList, {
  ConditionalCell,
  ConditionEquals,
  TextFieldCell,
  UnixDateOnlyCell,
} from "../components/dataList/StaticDataList";

import { State } from "../redux/reducers";
import { Season } from "../model/Season";
import { useNavigate } from "react-router-dom";
import { Survey } from "../model/Survey";

import DoneIcon from "@mui/icons-material/Done";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WarningIcon from "@mui/icons-material/Warning";
import BlockIcon from '@mui/icons-material/Block';


const SurveyListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filter, surveys } = useSelector((state: State) => state.surveyList);
  const { seasons } = useSelector((state: State) => state.app);
  const { loading } = useSelector((state: State) => state.ui);

  useEffect(() => dispatch(initSurveysPage()), [dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
            paddingTop: 2,
            paddingBottom: 3,
          }}
        >
          <FilterHeader
            value={filter}
            onChange={(value: SurveyFilter) => dispatch(setSurveyFilter(value))}
            filters={[
              {
                property: "season",
                label: "Sæson",
                type: "select",
                values: seasons.map(
                  (s: Season): FilterValue => ({
                    label: s.label,
                    value: `${s.year}`,
                  })
                ),
              },
              {
                property: "fetchall",
                label: "Vis alle",
                type: "boolean", 
              }
            ]}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <StaticDataList
          loading={loading}
          items={surveys}
          onClick={(item: Survey) => navigate("/survey/" + item.id)}
        >
          <ConditionalCell title="Status" source="status">
            <ConditionEquals case="approved">
              <Tooltip title='Status: Godkendt'>
                <DoneIcon color="success" fontSize="small" />
              </Tooltip>
            </ConditionEquals>
            <ConditionEquals case="draft">
              <Tooltip title='Status: Kladde'>
                <AssignmentIcon color="inherit" fontSize="small" />
              </Tooltip>
            </ConditionEquals>
            <ConditionEquals case="importing">
              <Tooltip title='Ikke færdig-importeret'>
                <WarningIcon color="error" fontSize="small" />
              </Tooltip>
            </ConditionEquals>
            <ConditionEquals case="deleted">
              <Tooltip title='Slettet'>
                <BlockIcon color="error" fontSize="small" />
              </Tooltip>
            </ConditionEquals>
          </ConditionalCell>
          <TextFieldCell title="Sæson" source="season" />
          <TextFieldCell title="Navn" source="name" />
          <UnixDateOnlyCell unit="ms" title="Dato" source="date" />
          <TextFieldCell minWidth={95} title="Fly" source="callsign" />
          <TextFieldCell title="Områder" source="areas" />
          <TextFieldCell title="Venstre side" source="observerLeft" />
          <TextFieldCell title="Højre side" source="observerRight" />
        </StaticDataList>
      </Grid>
    </Grid>
  );
};
export default SurveyListPage;
