import produce from "immer";
import { Environment } from "../../../model/Environment";
import { EnvironmentSetAction, ENVIRONMENT_SET, ENVIRONMENT_SET_PAGE } from "./environmentActions";

export interface EnvironmentStore {
    environment: Environment[] | null;
    currentPage: number;
    pageSize: number;
}

const initialState: EnvironmentStore = {
  environment: null,
  currentPage: 1,
  pageSize: 20
};

const environment = (state = initialState, action: any) => {
  switch (action.type) {
    case ENVIRONMENT_SET_PAGE:
      return produce(state, (draft: EnvironmentStore) => {
        draft.currentPage = action.payload
        return draft
      })
    case ENVIRONMENT_SET:
        const setEnvironmentAction : EnvironmentSetAction = action
        return produce(state, (draft: EnvironmentStore) => { 
                draft.currentPage = 1;
                draft.environment = setEnvironmentAction.payload; 
                return draft 
            })
    default:
      return state;
  }
};

export default environment;
