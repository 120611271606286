import { SetImportJobsAction, IMPORTJOB_SET_FILTER, IMPORTJOB_SET_IMPORTJOBS, IMPORTJOB_AUTOREFRESH_OFF, IMPORTJOB_AUTOREFRESH_ON } from "./importJobActions"
import { ImportJobFilter } from "../../model/ImportJobFilter";
import { ImportJobSimple } from "../../model/ImportJob";

export interface ImportJobStore {
  filter: ImportJobFilter;
  importJobs: ImportJobSimple[];
  autoRefresh: boolean;
}

const initialState : ImportJobStore = {
    filter: { dataLocationSearch: '', season: '', statuses: [] },
    importJobs: [],
    autoRefresh: false
}

const importJobStore = (state : ImportJobStore = initialState, action: any) : ImportJobStore => {
    switch (action.type) {
      case IMPORTJOB_AUTOREFRESH_OFF:
        return { ...state, autoRefresh: false }
        case IMPORTJOB_AUTOREFRESH_ON:
          return { ...state, autoRefresh: true }
      case IMPORTJOB_SET_IMPORTJOBS:
        const setImportJobsAction : SetImportJobsAction = action
        return { ...state, importJobs: setImportJobsAction.payload }
      case IMPORTJOB_SET_FILTER:          
        return { ...state, filter: action.payload }
      default:
        return state
    }
  }
  
  export default importJobStore;