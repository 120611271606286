import { useEffect, useRef } from "react";
import { Feature } from "ol";
import { FeatureCollection } from "../service/surveyApi";
import {
    Vector
} from 'ol/source';

import {
    GeoJSON
} from 'ol/format';
import Geometry from "ol/geom/Geometry";
import { Layer } from "ol/layer";



const createStaticGeoJsonSource = (sourceProjection: string, targetProjection: string, featureCollection: FeatureCollection) : Vector<Geometry> => {
    const source = new Vector({
        format: new GeoJSON({
            dataProjection: sourceProjection,
            featureProjection: targetProjection
        }),
        loader: async () => {           
            /*const format = source.getFormat()            
            if (format) {
                const features : Feature<Geometry>[] = format.readFeatures(featureCollection) as Feature<Geometry>[]
                source.addFeatures(features)                
            }*/
        }
    })
    return source
}

export interface OlStaticSourceProps {
    layer?: Layer<Vector<Geometry>>;
    featureCollection: FeatureCollection;
    sourceProjection: string;
    targetProjection: string;    
}

const OlStaticSource = ({ layer, featureCollection, sourceProjection, targetProjection}: OlStaticSourceProps) => {
    const sourceRef = useRef<Vector<Geometry>>(createStaticGeoJsonSource(sourceProjection, targetProjection, featureCollection))

    useEffect(() => {
        layer?.setSource(sourceRef.current)
    }, [ layer, sourceRef])

    useEffect(() => {
        const source = sourceRef.current
        source.clear()
        const format = source.getFormat()            
            if (format) {
                const features : Feature<Geometry>[] = format.readFeatures(featureCollection) as Feature<Geometry>[]
                source.addFeatures(features)                
            }
    }, [ featureCollection, sourceRef])

    return (<div></div>)
}

export default OlStaticSource