import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";


import OpenLayersMap, { OlFeature } from "../ol/OpenLayersMap";
import OlLayers, { OlTileLayer } from "../ol/OlLayers";
import OlWebGlPointsLayer from "../ol/OlWebGlPointsLayer";
import OlStaticSource from "../ol/OlSource";
import {transectLineStyle, webGlObservationStyle, webGlTracklogStyle } from '../components/styles'
import OlVectorLayer from "../ol/OlVectorLayer";
import OlView from "../ol/OlView";

import { Button, CircularProgress, Grid, LinearProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";


import { State } from "../redux/reducers";
import { Feature, FeatureCollection } from '../service/surveyApi'
import { Observation } from "../model/Observation";
import { Observer } from "../model/Observer";
import { Tracklog } from "../model/Tracklog";

import { showConfirmActionDialog, showMessage } from "../redux/ui/uiActions";
import { initSurveyPage, editSurveyMetaProperty, updateSurveyMeta, zoomTo, approveSurvey, deleteSurvey } from "../redux/survey/surveyActions";
import { selectObservations } from "../redux/survey/observations/observationActions";

import ObservationsListComponent from "../components/survey/ObservationsListComponent";
import EnvironmentListComponent from "../components/survey/EnvironmentListComponent";

import { StringListFieldEdit } from "../components/edit/ListFieldEdit";
import { SelectOption, StaticDropdownEdit } from "../components/edit/DropdownField";
import StaticDataList, { TextFieldCell, UnixTimeOnlyCell } from "../components/dataList/StaticDataList";
import EditComponent, { DateFieldEdit, TextAreaEdit, TextFieldEdit, DateTimeFieldEdit, ElapsedTimeFieldStaticEdit, NumberFieldEdit } from "../components/EditComponent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper/Paper";
import { Survey } from "../model/Survey";

const observationsToFeatureCollection = (observations: Observation[] | null, selectedId: number | null) : FeatureCollection => {
  if (observations === null) {
    return emptyFeatureCollection()
  }
  const features : Feature[] = observations.map((i: any, idx: number) => ({
    type: 'Feature',
    geometry: {
        type: 'Point',
        coordinates: i.position.coordinates
    },
    properties: {
        "id": i.id,
        "idx" :idx,
        isSelected: i.id === selectedId,
        count: i.count,
        speciesId: i.speciesId,
    }
  }))
  return { type: 'FeatureCollection', features }
}

const transectLinesToFeatureCollection = (transectLines: any[] | null) : FeatureCollection => {
  if (transectLines === null) {
    return emptyFeatureCollection()
  }
  const features : Feature[] = transectLines.map((i: any, idx: number) => ({
    type: 'Feature',
    geometry: i.line,
    properties: {
        "id": i.id,
        "idx" :idx,
        transectName: i.transectName,
        isSelected: i.isSelected,
        startTime: i.startTime,
        endTime: i.endTime,
        effortType: i.effortType
    }
  }))
  return { type: 'FeatureCollection', features }
}

const tracklogToFeatureCollection = (tracklog: Tracklog[] | null) : FeatureCollection => {
  if (tracklog === null) {
    return emptyFeatureCollection()
  }
  const features : Feature[] = tracklog.map((i: Tracklog, idx: number) => ({
      type: 'Feature',
      geometry: {
          type: 'Point',
          coordinates: i.position.coordinates
      },
      properties: {
          "id": i.id,
          "idx" :idx,
          timestamp: Math.floor(i.timestampms / 1000),
      }
  }))
  return { type: 'FeatureCollection', features }
}

const emptyFeatureCollection = () : FeatureCollection => ({ type: 'FeatureCollection', features: [] })

const SurveyEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { shadowSurvey, survey, transectLines, observers, mapView} = useSelector(
    (state: State) => state.surveyPage
  );
  const { tracklog, loading: tracklogLoading } = useSelector((state: State) => state.tracklog)
  const { selectedObservation, filteredObservations, loading: observationsLoading } = useSelector((state: State) => state.observations)
  const _survey = shadowSurvey || survey
  
  const [ selectedTab, setSelectedTab ] = useState('metadata')
  const mapSize = selectedTab === 'transectlines' || selectedTab === 'environment' ? 12 : 5

  const observationCollection = observationsToFeatureCollection(filteredObservations, selectedObservation)

  useEffect(() => {
    if (!id) {
      dispatch(
        showMessage({ title: "UI Error", text: "Kan ikke vise survey uden id" })
      );
      return;
    }
    dispatch(initSurveyPage(parseInt(id)));
  }, [id, dispatch]);

  if (
    id === null ||
    _survey === null
  ) {
    return (<div style={{ marginTop: 24 }}><LinearProgress /></div>);
  }
  const tracklogFeatures = tracklogToFeatureCollection(tracklog)
  const startTime = tracklog && tracklog.length > 0 ? tracklog[0].timestampms : null
  const endTime = !tracklogLoading && tracklog && tracklog.length > 0  ? tracklog[tracklog.length - 1].timestampms : null
  const flightTime = (endTime && startTime) ? (endTime - startTime) : null
  return (
      <Grid container spacing={2}>
        { survey?.status === 'importing' && 
          <Grid xs={12} item>
            <Paper sx={{mt: 1, p: 1}}>
              <Typography variant='h6'>
                Vigtigt! 
                </Typography>
                <Typography variant='body1'>
                  Denne survey er ikke færdig importeret. Gå til Imports for at se hvorfor.
              </Typography>
            </Paper>
          </Grid>
        }
        
        <Grid sx={{mt: 1}} item xs={12}>
          <ToggleButtonGroup sx={{ padding: 0, margin: 0}} exclusive color='primary' value={selectedTab} onChange={(evt, value) => value && setSelectedTab(value)}>
            <ToggleButton value='metadata'>Metadata</ToggleButton>
            <ToggleButton value='observations'>Observationer</ToggleButton>
            <ToggleButton value='transectlines'>Transects</ToggleButton>
            <ToggleButton value='environment'>Miljøvariable</ToggleButton>   
          </ToggleButtonGroup>
        </Grid>        
        { selectedTab === 'metadata' && 
          <React.Fragment>
            <Grid item xs={12} md={12 - mapSize}>
              <div style={{ height: 8 }}/>
              <EditComponent 
                item={_survey} 
                save 
                dirty={shadowSurvey !== null} 
                onSave={() => dispatch(updateSurveyMeta(_survey.id))}
                onChange={(source: keyof Survey, value: any) => dispatch(editSurveyMetaProperty(source, value))}>
                <TextFieldEdit<Survey> title='Survey Id' source='id' readonly/>
                <TextFieldEdit<Survey> title='Navn' source='name' readonly/>
                <TextFieldEdit<Survey> title='Sæson' source='season' />
                <TextFieldEdit<Survey> readonly title='Projekt ID' source='projectId' />
                <DateFieldEdit title='Dato' source='date' />
                <DateTimeFieldEdit readonly unit='ms' title='Tracklog start' value={startTime} />
                <DateTimeFieldEdit readonly unit='ms' title='Tracklog slut' value={endTime} />
                <ElapsedTimeFieldStaticEdit unit='ms' readonly title='Flyvetid' value={flightTime} />
                <TextFieldEdit<Survey> title='Lufthavn' source='airport' />
                <TextAreaEdit title='Områder' source='areas' />
                <NumberFieldEdit title='Transect bånd offset' source='distbandOffset' />
                <StringListFieldEdit title='Transect bånd' source='distbands' />
                <NumberFieldEdit title='Flight altitude' source='flightAltitude' />
                <StaticDropdownEdit 
                  title='Observatør (venstre)' 
                  source='observerLeft' 
                  options={observers} 
                  optionToValue={(v: SelectOption | null) => v ? v.value : null}
                  itemMapFn={(i: string) => ({label: i, value: i})}
                  mapFn={(o : Observer) => ({ label: o.initials, value: o.initials })} 
                  />
                <StaticDropdownEdit 
                  title='Observatør (højre)' 
                  source='observerRight'  
                  options={observers} 
                  optionToValue={(v: SelectOption | null) => v ? v.value : null}
                  itemMapFn={(i: string) => ({label: i, value: i})}
                  mapFn={(o : Observer) => ({ label: o.initials, value: o.initials })} 
                  />
                <TextFieldEdit<Survey> title='Fly' source='callsign' />
                <TextFieldEdit<Survey> title='Pilot' source='pilot' />              
                <TextFieldEdit<Survey> title='Status' source='status' readonly />
                <TextFieldEdit<Survey> title='Godkendt af' source='approvedBy' readonly />
                <DateTimeFieldEdit readonly title='Godkendt den' source='approvedDate' unit='ms' />
              </EditComponent>     
              <div style={{ marginTop: 8 }} />       
              <Button disabled={_survey.status === 'approved'} variant='contained' fullWidth 
                onClick={() => dispatch(approveSurvey(_survey.id))}
                >
                Godkend
              </Button>
              <div style={{ marginTop: 8 }} />       
              <Button variant='contained' color={'error'} fullWidth 
                onClick={() => dispatch(
                  showConfirmActionDialog(
                    { title: 'Slet survey', text: 'Dette vil slette den valgte survey' }, 
                    deleteSurvey(_survey.id))
                  )}
              >
                Slet
              </Button>
            </Grid>
          </React.Fragment>
        }
        { selectedTab === 'observations' &&
        <Grid item xs={12} md={12 - mapSize}>
          {filteredObservations !== null ?
            <ObservationsListComponent loading={observationsLoading} onSelect={(item: Observation) => { dispatch(selectObservations(item.id)); dispatch(zoomTo(item.position))}} observations={filteredObservations} selectedObservation={selectedObservation} visible={selectedTab === 'observations'} />          
            :
            <CircularProgress />
          }
        </Grid>}
        {
          (survey && selectedTab === 'environment') &&
          <Grid item xs={12}>
            <EnvironmentListComponent surveyId={survey.id} />
          </Grid>
        }        
        { selectedTab !== 'environment' && 
          <Grid item xs={12} md={mapSize}>
            <OpenLayersMap id="test" refreshSizeToken={mapSize} height={700} top={16} left={0} right={0}>
              <OlView lat={mapView.lat} lon={mapView.lon} zoom={mapView.zoom} />
              <OlLayers>
                <OlTileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <OlWebGlPointsLayer
                  visible={selectedTab === 'observations'}
                  title="Observationer"
                  style={webGlObservationStyle}
                  onFeatureClicked={(features: OlFeature[]) => { features.length === 1 && dispatch(selectObservations(features.map(f => f.getProperties().id)[0]))}}>
                    <OlStaticSource sourceProjection='EPSG:4326' targetProjection='EPSG:3857' featureCollection={observationCollection} />
                  </OlWebGlPointsLayer>
                <OlWebGlPointsLayer
                  visible={selectedTab === 'tracklog' || selectedTab === 'metadata'}            
                  title="Tracklog"            
                  style={webGlTracklogStyle}>
                  <OlStaticSource sourceProjection='EPSG:4326' targetProjection='EPSG:3857' featureCollection={tracklogFeatures} />
                </OlWebGlPointsLayer>
                <OlVectorLayer
                  visible={selectedTab === 'transectlines'}
                  style={transectLineStyle}>
                    <OlStaticSource sourceProjection='EPSG:4326' targetProjection='EPSG:3857' featureCollection={transectLinesToFeatureCollection(transectLines)} />
                </OlVectorLayer>
              </OlLayers>
            </OpenLayersMap>
          </Grid>
        }
        {
          (transectLines && selectedTab === 'transectlines') && 
          (
            <Grid item xs={12}>
              <StaticDataList items={transectLines.sort((a: any, b: any) => a.startTime - b.startTime)}>
                <TextFieldCell title='Transect' source='transectName' />
                <UnixTimeOnlyCell title='Start' source='startTime' unit='ms' />
                <UnixTimeOnlyCell title='End' source='endTime' unit='ms' />
                <TextFieldCell title='Type' source='effortType' />
              </StaticDataList>
            </Grid>
          )
        }
      </Grid>
  );
};
//                    <OlWebGlPointLayer visible={state} title='Observationer' featureCollection={observations} style={styleByCount} />

export default SurveyEditPage;
