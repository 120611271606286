import Select from "react-select";

import { EditComponentChild } from "../EditComponent";

export interface SelectOption {
  label: string;
  value: string;
}

export interface StaticDropdownEditProps {
  item?: any;
  source: string;
  readonly?: boolean;
  options: any[];
  mapFn: (i: any) => SelectOption;
  itemMapFn?: (i: any) => SelectOption;
  optionToValue: (o: SelectOption | null) => any;
}

export const StaticDropdownEdit = (props: any): EditComponentChild => {
  const {
    item,
    source,
    readonly,
    options,
    mapFn,
    itemMapFn,
    onChange,
    optionToValue,
  } = props;
  let disabled = readonly;
  if (typeof readonly === "undefined") {
    disabled = false;
  }
  const _options = options.map(mapFn);
  const value = itemMapFn ? itemMapFn(item[source]) : mapFn(item[source]);
  return (
    <Select
      isDisabled={disabled}
      value={value}
      options={_options}
      onChange={(v) => onChange(optionToValue(v))}
    />
  );
};
