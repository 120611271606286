import { Observation, ObservationFilter } from "../../../model/Observation";
import { 
  ObservationAppendAction, 
   OBSERVATION_APPEND, 
   OBSERVATION_LOADED, 
   OBSERVATION_RESET, 
   OBSERVATION_SELECT_OBSERVATIONS, 
   OBSERVATION_SET_FILTERED_OBSERVATIONS, 
   OBSERVATION_SET_OBSERVATIONS_FILTER, 
   OBSERVATION_SET_OBSERVATIONS_PAGE, 
   SelectObservationsAction, 
   SetObservationsAction 
} from "./observationActions";

export interface ObservationStore {
    surveyId: number | null;
    selectedObservation: number | null;
    observations: Observation[] | null;
    filteredObservations: Observation[] | null;
    currentPage: number;
    pageSize: number;
    pages: number;
    filter: ObservationFilter;
    loading: boolean;
  }
  
  const initialState: ObservationStore = {
    surveyId: null,
    observations: null,
    filteredObservations: null,
    selectedObservation: null,
    currentPage: 0,
    pages: 0,
    pageSize: 20,
    filter: {
      minCount: 1,
      speciesId: null
    },
    loading: false
  };
  
  const observationStore = (
    state: ObservationStore = initialState,
    action: any
  ): ObservationStore => {
    switch (action.type) {    
      case OBSERVATION_SELECT_OBSERVATIONS:
        if (!state.observations || state.observations.length === 0) {
          return state;
        }
        const selectObservationAction: SelectObservationsAction = action;        
        return {
          ...state,
          selectedObservation: selectObservationAction.payload.id
        };
      case OBSERVATION_SET_FILTERED_OBSERVATIONS:
        const setFilteredObservationsActions: SetObservationsAction = action;
        const pages = Math.floor((setFilteredObservationsActions.payload.length - 1) / state.pageSize) + 1
        return { ...state, pages, filteredObservations: setFilteredObservationsActions.payload };
      case OBSERVATION_SET_OBSERVATIONS_FILTER:
        const filter = action.payload
        return { ...state, filter }
      case OBSERVATION_SET_OBSERVATIONS_PAGE:
        const setObservationPageAction: any = action;
        return { ...state, currentPage: setObservationPageAction.payload };  
      case OBSERVATION_RESET:
        return { ...state, surveyId: action.payload, currentPage: 1, pages: 1, observations: [], loading: true }
      case OBSERVATION_APPEND:
        const appendAction : ObservationAppendAction = action
        const o = state.observations || []
        const no = o.concat(appendAction.payload.observations)    
        return { ...state, observations: no }
      case OBSERVATION_LOADED: 
        return { ...state, loading: false }
      default:
        return state;
    }
  };
  
  export default observationStore;