import React, { useState } from "react";

import {
  TextField,
  Button, 
  Typography 
} from "@mui/material";
import { useDispatch } from "react-redux";
import {  
  requestNewPassword,
} from "../../redux/app/appActions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/1600x900/?nature,water)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const NewPasswordRequiredComponent = function ({
  username,
}: {
  username: string;
}) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [name, setName] = useState("");
  const classes = useStyles();

  const passwordValid = password.length > 10 && password === password2;

  return (
    <React.Fragment>
      <Typography component="h1" variant="h5">
        Enter new password
      </Typography>
      <div className={classes.form}>
        <TextField
          disabled
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password2"
          label="Password"
          type="password"
          id="password2"
          value={password2}
          onChange={(evt) => setPassword2(evt.target.value)}
        />
        <Button
          color="primary"
          fullWidth
          variant="contained"
          disabled={!passwordValid}
          onClick={() => dispatch(requestNewPassword(name, password))}
        >
          Update password
        </Button>
      </div>
    </React.Fragment>
  );
};

export default NewPasswordRequiredComponent;
