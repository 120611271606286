import {Style, Stroke} from 'ol/style';

const z = ["interpolate", ["linear"], ["zoom"], 6, 200, 10, 30]
export const webGlTracklogStyle = {
  symbol: {
    symbolType: "circle",
    size: ["interpolate", ["linear"], ["zoom"], 6, 1, 10, 8],
    color: "rgb(255, 128, 128)",
    opacity: [
        'clamp',
        ['/',
        ['%', ['get', 'idx'], z],
        ['*', 0.8, z]], 0.4, 1
    ],
  },
};

export const webGlObservationStyle = {
  symbol: {
    symbolType: "circle",
    size: [
      "*",
      ["interpolate", ["exponential", 0.4], ["get", "count"], 1, 4, 10000, 32],
      ["interpolate", ["linear"], ["zoom"], 5, 1, 10, 2]
    ],
    color: [
        'case',
        ['==', ['get', 'isSelected'], 1], 'rgb(255,96,96)',
        'rgb(128,128,255)'
      ],
    opacity: [
      'case',
      ['==', ['get', 'isSelected'], 1], 1,
      0.75
    ],
  },
};


const transectStyle =  new Style({
  stroke: new Stroke({
    width: 2.5,
    color: "rgb(255, 128, 128)"
  })
})

const totaltaellingStyle = new Style({
  stroke: new Stroke({
    width: 2,
    color: '#ab0023'
  })
})

export const transectLineStyle = (feature) => {
 // console.log(feature.get('effortType'))
 // console.log(feature)
  if (feature.get('effortType') === 'TRANSECT') {
    return transectStyle
  } else {
    return totaltaellingStyle
  }
}