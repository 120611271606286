import { combineReducers } from 'redux'
import surveyList, { SurveyListStore } from '../surveyList/surveyListReducer'
import surveyPage, { SurveyStore } from '../survey/surveyReducer'
import importjob, { ImportJobStore } from '../importjob/importJobReducer'
import ui, { UiStore } from '../ui/uiReducer'
import app, { AppStore } from '../app/appReducer'
import importTaskList, { ImportTaskListStore } from '../importTaskList/importTaskListReducer'
import environment, { EnvironmentStore } from '../survey/environment/environmentReducer'
import tracklog, { TracklogStore } from '../survey/tracklog/tracklogReducer'
import observations, { ObservationStore } from '../survey/observations/observationReducer'
import upload, { UploadStore } from '../upload/uploadReducer'
export interface State {
  app: AppStore;
  surveyPage: SurveyStore;
  surveyList: SurveyListStore;
  environment: EnvironmentStore;
  observations: ObservationStore;
  tracklog: TracklogStore;
  importjob: ImportJobStore;
  importTaskList: ImportTaskListStore;
  upload: UploadStore;
  ui: UiStore;
}

const appReducer = combineReducers({
  app,
  surveyPage,
  tracklog,
  observations,
  environment,
  surveyList,
  importjob,
  importTaskList,
  upload,
  ui
});

const rootReducer = (state: any, action: any) => {
  // TODO - logout logic
  return appReducer(state, action)
}

export default rootReducer