import { Tracklog } from "../../../model/Tracklog";
import {
  TracklogAppendAction,
  TRACKLOG_APPEND,
  TRACKLOG_LOADED,
  TRACKLOG_RESET
} from "./tracklogActions";


export interface TracklogStore {
  surveyId: number | null;
  tracklog: Tracklog[] | null;
  loading: boolean;
}

const initialState: TracklogStore = {
  surveyId: null,
  tracklog: null,
  loading: false
};

const tracklogStore = (
  state: TracklogStore = initialState,
  action: any
): TracklogStore => {
  switch (action.type) {    
    case TRACKLOG_RESET:
      return { ...state, surveyId: action.payload, tracklog: [], loading: true }
    case TRACKLOG_APPEND:
      const appendAction : TracklogAppendAction = action
      const t = state.tracklog || []
      const nt = t.concat(appendAction.payload.tracklog)    
      return { ...state, tracklog: nt }
    case TRACKLOG_LOADED: 
      return { ...state, loading: false }
    default:
      return state;
  }
};

export default tracklogStore;
