import { SurveyFilter } from "../../model/SurveyFilter"

export const SURVEYS_INIT = "[surveylist] init"
export const SURVEYS_SET_FILTER = "[surveylist] set filter"
export const FETCH_SURVEYS: string = "[surveylist] fetch"
export const FETCH_SURVEYS_INIT: string = "[surveylist] fetch init"
export const FETCH_SURVEYS_DONE: string = "[surveylist] fetch done"
export const SURVEYS_SET_SURVEYS = "[surveylist] set surveys"

export interface SetSurveyFilterAction {
    type: string;
    payload: SurveyFilter
}

export interface SetSurveysAction {
    type: string;
    payload: Array<any>
}

export const setSurveyFilter = (filter: SurveyFilter) : SetSurveyFilterAction => ({
    type: SURVEYS_SET_FILTER,
    payload: filter
})

export const fetchSurveys = (filter: SurveyFilter) : SetSurveyFilterAction => ({
    type: FETCH_SURVEYS,
    payload: filter
})

export const setSurveys = (surveys: Array<any>) : SetSurveysAction => ({
    type: SURVEYS_SET_SURVEYS,
    payload: surveys
})

export const initSurveysPage = () : any => ({
    type: SURVEYS_INIT
})
