import { Observer } from '../../model/Observer'
import { Survey } from '../../model/Survey'
import { FeatureCollection } from '../../service/surveyApi'
import { ReduxAction } from '../actionModel'

export const SURVEY_INIT = '[survey] init'
export const SURVEY_FETCH = '[survey] fetch'
export const SURVEY_SET_SURVEY = '[survey] set survey'
export const SURVEY_CLEAR_SURVEY = '[survey] clear survey'
export const SURVEY_SET_TRACKLOG = '[survey] set tracklog'
export const SURVEY_SET_TRANSECTLINES = '[survey] set transectlines'

export const SURVEY_META_EDIT_PROPERTY = '[survey] [meta] edit property'
export const SURVEY_SET_SHADOW_SURVEY = '[survey] set shadow survey'

export const SURVEY_DELETE = '[survey] delete'
export const SURVEY_APPROVE = '[survey] approve'
export const SURVEY_META_UPDATE = '[survey] [meta] store'
export const SURVEY_PAGE_SET_OBSERVERS = '[survey] set observers'

export const SURVEY_MAP_ZOOM_TO = '[survey] [map] zoom to'

export interface SetSurveyAction {
  type: string
  payload: Survey
}

export interface ClearSurveyAction {
  type: string
}

export interface SetShadowSurveyAction {
  type: string
  payload: Survey | null
}

export interface SetTracklogAction {
  type: string
  payload: FeatureCollection
}

export interface SetTransectLinesAction {
  type: string
  payload: any[]
}
export interface InitSurveyAction {
  type: string
  payload: { id: number }
}

export interface FetchSurveyAction {
  type: string
  payload: { id: number }
}

export interface UpdateSurveyAction {
  type: string
  payload: { id: number }
}

export interface DeleteSurveyAction {
  type: string
  payload: { surveyId: number }
}

export const initSurveyPage = (id: number): InitSurveyAction => ({
  type: SURVEY_INIT,
  payload: { id },
})

export const deleteSurvey = (surveyId : number) : DeleteSurveyAction => ({
  type: SURVEY_DELETE,
  payload: { surveyId }
})

export const clearSurvey = (): ClearSurveyAction => ({
  type: SURVEY_CLEAR_SURVEY,
})

export const setSurvey = (survey: Survey): SetSurveyAction => ({
  type: SURVEY_SET_SURVEY,
  payload: survey,
})

export const setTransectLines = (transectlines: any[]): SetTransectLinesAction => ({
  type: SURVEY_SET_TRANSECTLINES,
  payload: transectlines,
})

export const fetchSurvey = (id: number): FetchSurveyAction => ({
  type: SURVEY_FETCH,
  payload: { id },
})

export interface EditSurveyMetaAction {
  type: string
  payload: { property: keyof Survey; value: any }
}

export const editSurveyMetaProperty = (property: keyof Survey, value: any): EditSurveyMetaAction => ({
  type: SURVEY_META_EDIT_PROPERTY,
  payload: { property, value },
})

export const setShadowSurvey = (survey: Survey | null): SetShadowSurveyAction => ({
  type: SURVEY_SET_SHADOW_SURVEY,
  payload: survey,
})

export const updateSurveyMeta = (id: number): UpdateSurveyAction => ({
  type: SURVEY_META_UPDATE,
  payload: { id },
})

export interface SetObserversAction {
  type: string
  payload: Observer[]
}

export const setObservers = (observers: Observer[]): SetObserversAction => ({
  type: SURVEY_PAGE_SET_OBSERVERS,
  payload: observers,
})

export const zoomTo = (position: any): any => ({
  type: SURVEY_MAP_ZOOM_TO,
  payload: position,
})

export interface ApproveSurveyAction extends ReduxAction {
  payload: { surveyId: number }
}

export const approveSurvey = (surveyId: number) => ({
  type: SURVEY_APPROVE,
  payload: { surveyId },
})
