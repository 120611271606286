import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, IconButton } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { State } from "../../redux/reducers";
import { 
    initEnvironment,
    environmentPrevPage,
    environmentNextPage
} from "../../redux/survey/environment/environmentActions";

import StaticDataList, { TextFieldCell, BooleanFieldCell, UnixTimeOnlyCell } from "../dataList/StaticDataList";

export interface EnvironmentListComponentProps {
    surveyId: number;
}

const EnvironmentListComponent = (props: EnvironmentListComponentProps) : ReactElement | null => {
    const { surveyId } = props
    const dispatch = useDispatch()
    const {environment, currentPage, pageSize} = useSelector((state: State) => state.environment)

    useEffect(() => {
        dispatch(initEnvironment(surveyId))
    }, [ surveyId, dispatch ])
    if (!environment) {
        return null
    }
    const pages = Math.floor((environment.length - 1) / pageSize) + 1
    const offset = pageSize * (currentPage - 1)
    return (
        <Grid container>
            <Grid item xs={12}>
            <IconButton onClick={() => dispatch(environmentPrevPage())}><ChevronLeftIcon /></IconButton>
            {` ${currentPage} / ${pages}`}
            <IconButton onClick={() => dispatch(environmentNextPage())}><ChevronRightIcon /></IconButton>
            </Grid>
            <Grid item xs={12}>
                <StaticDataList items={environment} offset={offset} limit={pageSize} dense>
                    <UnixTimeOnlyCell title='Tid' source='timestampMs' unit='ms' />
                    <TextFieldCell title='Sea state' source='seaState' />          
                    <TextFieldCell title='Sun intensity' source='sunIntensity' />
                    <TextFieldCell title='Effort type' source='effortType' />
                    <BooleanFieldCell title='Left on' source='leftSideActive' />
                    <BooleanFieldCell title='Right on' source='rightSideActive' />
                    <TextFieldCell title='Transect' source='transectName' />
                    <TextFieldCell title='Kommentar' source='misc' />
                </StaticDataList>
            </Grid>
            
        </Grid>
    )
}

export default EnvironmentListComponent