import {
    apply,
    call,
    put,
    delay
} from 'redux-saga/effects'
import { loading, showMessage, stopLoading } from '../ui/uiActions';
import {
    authError
} from "./apiActions";

export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    statusText?:string
}

function* apiRequestSaga(obj: any, fn: any, args: Array<any>, statusMapper: any) {
    yield put(loading())
    for (let i = 0; i < 7; i++) {
        try {
            const data : ResponseGenerator = yield apply(obj, fn, args)
            if (statusMapper["200"]) {
                yield put(statusMapper["200"](data))
            }
            if (statusMapper["ok"]) {
                yield put(statusMapper["ok"](data))
            }
            yield put(stopLoading())
            return data
        } catch (error: any) {
            console.log('apiRequest got error', error)
            if (error.status && (error.status === 401 || error.status === 403)) {
                yield put(authError())
                yield put(stopLoading())
                return
            }        
            if (error.status) {
                const status = "" + error.status
                if (statusMapper[status]) {
                    yield put(statusMapper[status])
                    yield put(stopLoading())
                    return
                }
            }
            yield delay(1000)
        }
    }
    if (statusMapper.error) {
        yield put(statusMapper.error())
    } else {
        yield put(showMessage({ title: 'Fejl', text: 'Kunne ikke forbinde til api'}))
    }
    yield put(stopLoading())
}

export const apiRequest = (obj: any, fn: any, args: Array<any>, statusMapper: any) : any => {
    return call(apiRequestSaga, obj, fn, args, statusMapper || {})
}