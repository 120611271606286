import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import store from "./redux/store";
import { AuthComponent } from "./components/login/SignInComponent";
import es6Promise from 'es6-promise';
import 'whatwg-fetch'

import Amplify from "aws-amplify";

es6Promise.polyfill();

const mdTheme = createTheme({
  typography: {
    h3: {
      fontSize: 22
    },
    h4: {
      fontSize: 18,
      fontWeight: 'bold'
    }
  },
  palette: {
    background: {
      default: '#eee'
    }
  }
});

declare var amplifyconfig: any;
Amplify.configure(amplifyconfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={mdTheme}>
          <AuthComponent>
            <App />
          </AuthComponent>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type AppDispatch = typeof store.dispatch
