import deepEqual from 'deep-equal'
import { produce } from 'immer'
import { takeLeading, put, takeEvery, select } from '@redux-saga/core/effects'
import { apiRequest } from '../api/apiRequest'
import {
  InitSurveyAction,
  fetchSurvey,
  setSurvey,
  SURVEY_INIT,
  SURVEY_FETCH,
  FetchSurveyAction,
  SURVEY_META_EDIT_PROPERTY,
  EditSurveyMetaAction,
  setShadowSurvey,
  SURVEY_META_UPDATE,
  UpdateSurveyAction,
  setObservers,
  setTransectLines,
  SURVEY_APPROVE,
  ApproveSurveyAction,
  clearSurvey,
  SURVEY_DELETE,
  DeleteSurveyAction,
} from './surveyActions'
import SurveyApi from '../../service/surveyApi'
import { ListResponse, SingleResponse } from '../../model/ListResponse'
import { goto, showMessage } from '../ui/uiActions'
import { Survey, SurveyDiff } from '../../model/Survey'
import { State } from '../reducers'
import { Observer } from '../../model/Observer'
import { requestTracklog } from './tracklog/tracklogActions'
import { requestObservations } from './observations/observationActions'

function* processInitSurveyPage(action: InitSurveyAction) {
  try {
    yield put(clearSurvey())
    yield put(fetchSurvey(action.payload.id))
    yield apiRequest(SurveyApi, SurveyApi.loadObservers, [], {
      '200': (data: ListResponse<Observer>) => setObservers(data.items),
    })
  } catch (error) {
    yield put(showMessage({ title: 'Fejl', text: 'Uventet fejl i init survey page' }))
  }
}

function* processUpdateMeta(action: UpdateSurveyAction) {
  try {
    let { id } = action.payload
    let shadow: Survey | null = yield select((state: State) => state.surveyPage.shadowSurvey)
    const survey: Survey = yield select((state: State) => state.surveyPage.survey)
    if (!shadow) {
      return
    }
    const diff: SurveyDiff = {}
    for (const property in survey) {
      const p = property as keyof Survey
      if (survey[p] !== shadow[p]) {
        diff[p] = shadow[p]
      }
    }
    console.log('diff', id, diff)
    yield apiRequest(SurveyApi, SurveyApi.updateSurvey, [id, diff], {
      '200': (data: SingleResponse<Survey>) => setSurvey(data.item),
      error: () => showMessage({ title: 'API Fejl', text: 'Kunne ikke opdatere survey' }),
    })
  } catch (error) {
    yield put(showMessage({ title: 'Fejl', text: 'Uventet fejl da survey data skulle opdateres' }))
  }
}

function* processEditMetaProperty(action: EditSurveyMetaAction) {
  try {
    const { property, value } = action.payload
    let shadow: Survey | null = yield select((state: State) => state.surveyPage.shadowSurvey)
    const survey: Survey = yield select((state: State) => state.surveyPage.survey)
    if (!shadow) {
      shadow = survey
    }
    let newShadow: Survey | null = produce(shadow, (draft) => {
      //@ts-expect-error
      draft[property] = value
    })
    if (deepEqual(newShadow, survey)) {
      newShadow = null
    }
    yield put(setShadowSurvey(newShadow))
  } catch (error) {
    yield put(showMessage({ title: 'Fejl', text: 'Uventet fejl i editMetadata' }))
  }
}

function* processSurveyDelete(action: DeleteSurveyAction) {
  try {
    yield apiRequest(SurveyApi, SurveyApi.deleteSurvey, [action.payload.surveyId], {
      '200': () => goto('survey'),
      error: () => showMessage({ title: 'API Fejl', text: 'Kunne ikke slette survey' }),
    })
  } catch (error) {    
    yield put(showMessage({ title: 'Fejl', text: 'Uventet fejl i slet survey' }))
  }
}

function* processSurveyApprove(action: ApproveSurveyAction) {
  try {
    yield apiRequest(SurveyApi, SurveyApi.approveSurvey, [action.payload.surveyId], {
      '200': (data: SingleResponse<Survey>) => setSurvey(data.item),
      error: () => showMessage({ title: 'API Fejl', text: 'Kunne ikke opdatere survey' }),
    })
  } catch (error) {
    yield put(showMessage({ title: 'Fejl', text: 'Uventet fejl i godkend survey' }))
  }
}

function* processFetchSurvey(action: FetchSurveyAction) {
  try {
    yield apiRequest(SurveyApi, SurveyApi.loadSurvey, [action.payload.id], {
      '200': (data: SingleResponse<Survey>) => setSurvey(data.item),
      error: () => showMessage({ title: 'API Fejl', text: 'Kunne ikke hente survey' }),
    })
    yield put(requestTracklog(action.payload.id))
    yield put(requestObservations(action.payload.id))

    yield apiRequest(SurveyApi, SurveyApi.loadTransectLines, [action.payload.id], {
      '200': (data: ListResponse<any>) => setTransectLines(data.items),
    })
  } catch (error) {
    yield put(showMessage({ title: 'Fejl', text: 'Uventet fejl i fetchSurvey' }))
  }
}

export default function* watcher() {
  yield takeLeading(SURVEY_INIT, processInitSurveyPage)
  yield takeLeading(SURVEY_FETCH, processFetchSurvey)
  yield takeEvery(SURVEY_META_EDIT_PROPERTY, processEditMetaProperty)
  yield takeEvery(SURVEY_META_UPDATE, processUpdateMeta)
  yield takeLeading(SURVEY_APPROVE, processSurveyApprove)
  yield takeLeading(SURVEY_DELETE, processSurveyDelete)
}
