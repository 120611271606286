import React, { useState } from "react";

import { Button, Chip, TextField } from "@mui/material";

import { EditComponentChild } from "../EditComponent";

export const StringListFieldEdit = (props: any): EditComponentChild => {
  const [showNew, setShowNew] = useState(false);
  const [newValue, setNewValue] = useState("");

  const [ editIndex, setEditIndex ] = useState<number | null>(null)
  
  const { item, source, onChange } = props;
  const value: any[] = item[source] || [];
  
  return (
    <div>
      { editIndex !== null && (
          <div>
          <TextField
            size='small'
            variant='standard'
            value={newValue}
            onChange={(e: any) => setNewValue(e.target.value)}
          />
          <Button
            size='small'
            color='primary'
            onClick={() => {
              onChange(value.map((e: any, idx: number) => idx === editIndex ? newValue : e));
              setEditIndex(null);
              setNewValue("");
            }}
          >
            Gem
          </Button>
          <Button
            size='small'
            color='inherit'
            onClick={() => {
              setEditIndex(null);
              setNewValue("");
            }}
          >
            Fortyd
          </Button>
        </div>
      )}
      {showNew && (
        <div>
          <TextField
            size='small'
            variant='standard'
            value={newValue}
            onChange={(e: any) => setNewValue(e.target.value)}
          />
          <Button
            size='small'
            color='primary'
            onClick={() => {
              onChange(value.concat([newValue]));
              setShowNew(false);
              setNewValue("");
            }}
          >
            Tilføj
          </Button>
          <Button
            size='small'
            color='inherit'
            onClick={() => {
              setShowNew(false);
              setNewValue("");
            }}
          >
            Fortyd
          </Button>
        </div>
      )}
      { !(showNew || editIndex !== null) &&
        <React.Fragment>
            {value.map((e: any, idx: number) => (
                <Chip
                    size='small'
                    key={idx}
                    label={e}
                    color='primary'
                    clickable
                    onClick={() => { setEditIndex(idx); setNewValue(e)}}
                    onDelete={() => onChange(value.filter((i: any) => i !== e))}
                />
            ))}
            <Chip size='small' label="+" clickable onClick={() => setShowNew(true)} />
        </React.Fragment>
      }
    </div>
  );
};
