import { CognitoUser } from 'amazon-cognito-identity-js';
import { Season } from '../../model/Season';
import { ReduxAction } from '../actionModel';
import { User } from "./appReducer"

export const APP_INIT = "[app] init"
export const APP_INIT_AUTH = "[app] init auth"
export const APP_REQUEST_LOGIN = "[app] request login"
export const APP_LOGIN_COMPLETE = "[app] login complete"
export const APP_REQUEST_NEW_PASSWORD = "[app] request new password"
export const APP_REQUEST_LOGOUT = "[app] request logout"
export const APP_SET_USER = "[app] set user"
export const APP_SET_LOGGED_OUT = "[app] set logged out"
export const APP_SET_CHALLENGE = "[app] set challenge"
export const APP_FORGOT_PASSWORD = "[app] forgot password"
export const APP_FORGOT_PASSWORD_SUBMIT = "[app] forgot password submit"

export const APP_SET_API_VERSION = "[app] set api version"
export const APP_SET_SEASONS = "[app] set seasons"

export const init = () : ReduxAction => ({
    type: APP_INIT
})


export interface SetUserAction {
    type: string;
    payload: User;
}

export const setUser = (user: User) : SetUserAction => ({
    type: APP_SET_USER,
    payload: user
})

export const setLoggedOut = () => ({
    type: APP_SET_LOGGED_OUT
})

export interface RequestLoginAction { 
    type: string;
    payload: {
        username: string;
        password: string;
    }
}

export interface RequestNewPasswordAction {
    type: string;
    payload: {
        name: string;
        password: string;
    }
}

export interface ForgotPasswordSubmitAction {
    type: string;
    payload: {
        username: string;
        code: string;
        password: string;
    }
}
export const forgotPassword = (username: string) => ({
    type: APP_FORGOT_PASSWORD,
    payload: username
})

export const forgotPasswordSubmit = (username: string, code: string, password: string) : ForgotPasswordSubmitAction => ({
    type: APP_FORGOT_PASSWORD_SUBMIT,
    payload: { username, code, password }
})

export const requestLogin = (username: string, password: string) : RequestLoginAction => ({
    type: APP_REQUEST_LOGIN,
    payload: { username, password}
})

export const loginComplete = () : ReduxAction => ({
    type: APP_LOGIN_COMPLETE
})

export const requestLogout = () => ({
    type: APP_REQUEST_LOGOUT
})

export const requestNewPassword = (name: string, password: string) : RequestNewPasswordAction => ({
    type: APP_REQUEST_NEW_PASSWORD,
    payload: { name, password }
})

export const initAuth = () => ({
    type: APP_INIT_AUTH
})

export interface AmplifyChallengeParam { 
    requiredAttributes: any[];
}      

export interface SignInAmplifyResponse extends CognitoUser {
    username: string,
    challengeParam: AmplifyChallengeParam
}

export interface SimpleUser { 
    username: string;
}

export interface SetChallengeAction {
    type : string;
    payload: {
        user: SignInAmplifyResponse | null | SimpleUser;
        challengeName: string;
        challengeParam: AmplifyChallengeParam | null;
    }
}

export const setChallenge = (user: SignInAmplifyResponse | SimpleUser | null, challengeName: string, challengeParam: AmplifyChallengeParam | null) : SetChallengeAction => ({
    type: APP_SET_CHALLENGE,
    payload: { user, challengeName, challengeParam }
})

export interface SetApiVersionAction extends ReduxAction {
    payload: { version: string }
}

export const setApiVersion = (version: string) : SetApiVersionAction => ({
    type: APP_SET_API_VERSION,
    payload: { version }
})

export interface SetSeasonsAction extends ReduxAction {
    payload: { seasons: Season[] }
}

export const setSeasons = (seasons: Season[]) : SetSeasonsAction => ({
    type: APP_SET_SEASONS,
    payload: { seasons }
})