import { delay, put, race, select, take, takeLeading } from "@redux-saga/core/effects";
import { ListResponse } from "../../model/ListResponse";
import { ImportJobFilter } from "../../model/ImportJobFilter";
import surveyApi from "../../service/surveyApi";
import { apiRequest, ResponseGenerator } from "../api/apiRequest";
import { showMessage } from '../ui/uiActions'
import { fetchImportJobs, 
    FETCH_IMPORTJOBS, 
    ImportJobResetAction, 
    IMPORTJOB_AUTOREFRESH_OFF, 
    IMPORTJOB_AUTOREFRESH_ON, 
    IMPORTJOB_INIT, 
    IMPORTJOB_RESET, 
    IMPORTJOB_DELETE, 
    IMPORTJOB_SET_FILTER, setAutoRefresh, setImportJobFilter, SetImportJobFilterAction, setImportJobs, initImportJobPage } from "./importJobActions";
import { State as FlydataState, State } from "../reducers";
import { ImportJobSimple } from "../../model/ImportJob";
import { ReduxAction } from "../actionModel";


function* processSetFilter(action: SetImportJobFilterAction) {
    try {
        yield put(fetchImportJobs(action.payload))
    } catch (error) {
        console.log(error)
    }
}

function* processInitImportJobListPage(action: any) {
    try {
        const filter : ImportJobFilter = yield select((state: FlydataState) => state.importjob.filter)
        yield put(setImportJobFilter(filter))
    } catch (error: any) {
        console.log('error', error)
    }
}

function* processFetchImportJobs(action: SetImportJobFilterAction) {
    try {
        const data: ResponseGenerator = yield apiRequest(surveyApi, surveyApi.loadImportJobs, [action.payload], {
            "200": (data: ListResponse<ImportJobSimple>) => setImportJobs(data.items),
            "error": () => showMessage({ title: 'Fejl', text: 'Kunne ikke hente import jobs' })
        })
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

function* processResetImportJob(action: ImportJobResetAction) {
    try {
        yield apiRequest(surveyApi, surveyApi.resetImportJob, [ action.payload ], {
            "error": () => showMessage({ title: 'Fejl', text: 'Kunne ikke resette importjob' })
        })
    } catch (error) {
        showMessage({ title: 'Fejl', text: 'Uventet fejl i reset importjob'})
        console.log(error)
    }
}

function* processDeleteImportJob(action: ImportJobResetAction) {
    try {
        yield apiRequest(surveyApi, surveyApi.deleteImportJob, [ action.payload ], {            
            "ok": () => initImportJobPage(),
            "error": () => showMessage({ title: 'Fejl', text: 'Kunne ikke slette importjob' })
        })
    } catch (error) {
        console.log(error)
    }
}


/* Worker Function */
function* pollImportJob(action: ReduxAction) {
    while (true) {
      try {
        const filter : ImportJobFilter = yield select((state : State) => state.importjob.filter)
        yield apiRequest(surveyApi, surveyApi.loadImportJobs, [ filter ], {
            "200": (data: ListResponse<ImportJobSimple>) => setImportJobs(data.items),
            "error": () => showMessage({ title: 'Fejl', text: 'Kunne ikke hente import jobs' })
        })
        yield delay(5000);
      } catch (err) {
        yield put(setAutoRefresh(false));
      }
    }
  }
  
  /* Watcher Function */
  function* pollTaskWatcher<E>(startAction : string, endAction: string, pollTask: (action : E) => void) {
    while (true) {
      const action: E = yield take(
          startAction
      );
      yield race([pollTask(action), take(endAction)]);
    }
  }

export default function* watcher() {
    yield takeLeading(IMPORTJOB_INIT, processInitImportJobListPage)
    yield takeLeading(IMPORTJOB_SET_FILTER, processSetFilter)
    yield takeLeading(FETCH_IMPORTJOBS, processFetchImportJobs)
    yield takeLeading(IMPORTJOB_RESET, processResetImportJob)
    yield takeLeading(IMPORTJOB_DELETE, processDeleteImportJob)
    yield pollTaskWatcher(IMPORTJOB_AUTOREFRESH_ON, IMPORTJOB_AUTOREFRESH_OFF, pollImportJob)
}
