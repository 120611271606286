import { 
  Grid, 
  Typography,
  FormGroup,
  FormControlLabel,
  Switch
} from "@mui/material";
import Select from "react-select";

export interface FilterValue {
  label: string;
  value: string;
}

const findSelectedValue = (value: string, values: Array<FilterValue>) : any => {
  return values.find((f : FilterValue) => f.value === value)
}

const SelectFilter = (props: any) => {
  const { value, onChange, label, values } = props;
  return (
    <Grid item xs={12}>
      <Typography variant="caption">{label}</Typography>
      <Select onChange={(value: any) => onChange(value.value)} value={findSelectedValue(value, values)} options={values} />
    </Grid>
  );
};

interface BooleanFilterProps {
  value: boolean
  onChange: (value: boolean) => void
  label : string  
}

const BooleanFilter = (props: BooleanFilterProps) => {
  const { value, onChange, label } = props;
  return (
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel 
            control={<Switch checked={value} onChange={evt => onChange(evt.target.checked)} />} 
            label={label} />
        </FormGroup>
      </Grid>
  )
}

const Filter = (props: any) => {
  const {value, onChange } = props;
  const { type, values, label } = props.definition;
  if (type === "select") {
    return <SelectFilter label={label} value={value} onChange={(v: any) => onChange(v)} values={values} />;
  } else if (type === 'boolean') {
    return <BooleanFilter label={label} value={value} onChange={v => onChange(v)} />
  } else {
    return null;
  }
};

const FilterHeader = (props: any) => {
  const { value, onChange } = props;
  const { filters } = props;

  const updateFilter = (property: string, v: any) => {
      console.log('update', property, v)
    const _v = { ...value };
    _v[property] = v;
    onChange(_v);
  };
  return (
    <Grid container>
      {filters.map((f: any, idx: number) => (
        <Filter
          key={idx}
          definition={f}
          value={value[f.property]}
          onChange={(v: any) => updateFilter(f.property, v)}
        />
      ))}
    </Grid>
  );
};

export default FilterHeader;
